import React from "react";
import { Link } from "react-router-dom";
import WomenInTechImage from "../../media/images/women-in-tech-bg.png";
import WomenInTechImageContent1 from "../../media/images/women-in-tech-content-1.png";
import WomenInTechImageContent2 from "../../media/images/women-in-tech-content-2.png";
import WomenInTechImageContent3 from "../../media/images/women-in-tech-content-3.png";
import WomenInTechImageContent4 from "../../media/images/women-in-tech-content-4.png";
import WomenInTechImageContent5 from "../../media/images/women-in-tech-content-5.png";
import ProjectManager from "../../media/images/proje-sorumlusu-sena.jpeg";
import LogoWithText from "../../media/images/logowithwhitetext.svg";
import WITLogo from "../../media/images/WIT_Logo.png";
import WITLogo_black from "../../media/images/WIT_Logo_black.png";
import { Helmet } from "react-helmet";
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Accordion from "@mui/material/Accordion";
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import PendingActionsIcon from '@mui/icons-material/PendingActions';
import AlarmOnIcon from '@mui/icons-material/AlarmOn';
import FlagIcon from '@mui/icons-material/Flag';
const steps = [

  {
    label: <div className="steps-container">
      <div className="steps-title">Başvuru Başlangıç</div>
      <div className="steps-date">23.09.2024</div>
    </div>,
    icon: PendingActionsIcon
  },
  {
    label: <div className="steps-container">
      <div className="steps-title">Program Başlangıç</div>
      <div className="steps-date">28.10.2024</div>
    </div>,
    icon: FlagIcon
  },
  {
    label: <div className="steps-container">
      <div className="steps-title">Program Bitiş</div>
      <div className="steps-date">1.08.2025</div>
    </div>,
    icon: AlarmOnIcon
  }

];


export default function WomenInTech() {
  const [expanded, setExpanded] = React.useState('panel1');

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };
  return (
    <div id="fh5co-main">
      <Helmet>

        {/* Title */}
        <title>WIN | ANKASOFT</title>

        {/* Description */}
        <meta name="description" content="Women in Tech (by ankasoft) inisiyatifi, kadınları teknoloji ve DevOps alanında güçlendirmeyi hedefler. Teknoloji sektöründe çeşitlilik ve eşitliği teşvik ederken, kadın mühendis adaylarının kariyer ve liderlik becerilerini geliştirmelerini sağlamayı amaçlıyoruz.
" />

        {/* Keywords (Not: Anahtar kelimeler artık genellikle dikkate alınmaz) */}
        <meta name="keywords" content="applications, strategic thinking, subject matter expertise, execution experience, corporate solutions, flexibility, business continuity, efficiency, system integration, information and communication technology services" />

        {/* Open Graph (og) Etiketleri */}
        <meta property="og:title" content="About | ANKASOFT" />
        <meta property="og:description" content="Women in Tech (by ankasoft) inisiyatifi, kadınları teknoloji ve DevOps alanında güçlendirmeyi hedefler. Teknoloji sektöründe çeşitlilik ve eşitliği teşvik ederken, kadın mühendis adaylarının kariyer ve liderlik becerilerini geliştirmelerini sağlamayı amaçlıyoruz." />
        <meta property="og:image" content="https://ankasoft.co/static/media/logo.c446759b3d8f3ed6c4701c8b31f77767.svg" />
        <meta property="og:url" content="https://ankasoft.co/about" />
        <meta property="og:type" content="website" />

      </Helmet>
      <div className="fh5co-narrow-content women-in-tech main" style={{ paddingBottom: 0 }}>
        <img src={WomenInTechImage} alt="" className="bg-image" />
        <div className="content">
          <div className="title-container">
            <div className="logo-container">
              <img src={LogoWithText} alt="" className="logo" />
              <div className="middle-line"></div>
              <img src={WITLogo} alt="" className="logo" />
            </div>
            {/*<div className="title">WOMEN IN TECH</div>*/}
            <div className="description">Kadınların gücüyle <br /> Geleceğin DevOps mühendislerini yetiştiriyoruz.</div>
          </div>
          <div className="description-container">
            Women in Tech, teknoloji dünyasında kadınların daha görünür ve etkin rol almasını sağlamak için tasarlanmış bir sosyal sorumluluk projesidir. Proje, kadınların teknoloji alanındaki liderliklerini güçlendirmeyi ve onlara yeni fırsatlar sunmayı hedefler. Teknoloji dünyasında cinsiyet farkı gözetmeksizin herkesin eşit fırsatlara sahip olması gerektiğine inanıyoruz ve bu projeyle daha kapsayıcı bir gelecek inşa ediyoruz.
          </div>
          <div className="signup-button-container">
            <a onClick={() => { window.open('https://forms.gle/gqU47vLSaepCKf7HA', 'popup', 'form') }} className="link">
              <span className="button">Hemen Kaydol</span>
            </a>
          </div>
        </div>
      </div>

      {/*<div className="fh5co-narrow-content">
        <h2 className="fh5co-heading animate-box">Our Services</h2>
      </div>*/}

      <div className="fh5co-narrow-content">
        <div className="row women-in-tech">
          <Stepper activeStep={0} alternativeLabel className="custom-stepper">
            {steps.map((item) => (
              <Step key={item.label}>
                <StepLabel StepIconComponent={item.icon}>{item.label}</StepLabel>
              </Step>
            ))}
          </Stepper>
        </div>

        <div className="women-in-tech contents-container">
          <div className="content-container">
            <div className="content-card">
              <div className="image-container">
                <img src={WomenInTechImageContent1} alt="" className="image" />
              </div>
              <div className="title-content-container">
                <div className="title">Women in Tech nedir?</div>
                <div className="content">
                  "Women in Tech" global bir harekettir ve dünya genelinde kadınların teknoloji sektöründe daha aktif ve görünür olmalarını teşvik etmeye yöneliktir. Bu hareket, kadınların STEM (bilim, teknoloji, mühendislik ve matematik) alanlarında kariyer yapmalarını ve liderlik pozisyonlarına yükselmelerini destekler.
                </div>
              </div>
            </div>
          </div>
          <div className="content-container">
            <div className="content-card">
              <div className="image-container">
                <img src={WomenInTechImageContent2} alt="" className="image" />
              </div>
              <div className="title-content-container">
                <div className="title">Biz kimiz?</div>
                <div className="content">
                  Şirketimizin kadın çalışanları tarafından oluşturulan bu insiyatif, 'DevOps' alanındaki en güncel araçları, teknolojileri ve uygulamaları öğrenmeyi amaçlayan bir eğitim programı geliştirerek, 'Women in Tech' hareketine destek olmak üzere kurulmuştur.
                </div>
              </div>
            </div>
          </div>
          <div className="content-container">
            <div className="content-card">
              <div className="image-container">
                <img src={WomenInTechImageContent3} alt="" className="image" />
              </div>
              <div className="title-content-container">
                <div className="title">Misyon:</div>
                <div className="content">
                  Teknoloji sektöründe kadın mühendis adaylarına IT sektöründe çalışmak motivasyonunu teşvik ederek daha kapsayıcı bir çalışma ortamı yaratmak için somut adımlar atmayı ve ölçülebilir hedeflere ulaşmayı taahhüt ediyoruz.
                </div>
              </div>
            </div>
          </div>
          <div className="content-container">
            <div className="content-card">
              <div className="image-container">
                <img src={WomenInTechImageContent4} alt="" className="image" />
              </div>
              <div className="title-content-container">
                <div className="title">Vizyon:</div>
                <div className="content">
                  Geleceğin teknoloji sektöründe, çeşitliliğin ve eşitliğin norm haline geldiği, herkesin yeteneklerini ve potansiyelini tam anlamıyla ifade edebildiği bir dünya yaratmayı vizyon ediniyoruz. 'DevOps' ve diğer IT disiplinlerinde kadın mühendis adaylarının liderliğini ve katılımını artırarak bu vizyona ulaşmayı hedefliyoruz.
                </div>
              </div>
            </div>
          </div>
          <div className="content-container">
            <div className="content-card">
              <div className="image-container">
                <img src={WomenInTechImageContent5} alt="" className="image" />
              </div>
              <div className="title-content-container">
                <div className="title">Hedefler:</div>
                <div className="content">
                  <ul class="wit-list">
                    <li class="wit-item"><b>Kadın Mühendis Adayı İstihdamını Artırmak:</b> Kadın mühendis adaylarının teknoloji sektöründe istihdamını artırarak ve onlara mentorluk yaparak bu alanda kendilerini geliştirmelerini sağlamak.</li>
                    <li class="wit-item"><b>Teknik Becerileri Geliştirmek:</b> Kadın mühendis adaylarına teknik beceri geliştirme fırsatları sunarak, atölyeler ve çevrimiçi kurslar aracılığıyla bilgi ve becerilerini artırmak.</li>
                    <li class="wit-item"><b>Liderlik ve Etkinliklerini Artırmak:</b> Kadın mühendis adaylarının liderlik rollerini teşvik etmek ve onlara kariyer gelişimi için eğitim ve mentorluk programları sunmak.</li>
                    <li class="wit-item"><b>Toplumsal Farkındalığı Artırmak:</b> Cinsiyet eşitsizliği konusunda toplumsal farkındalığı artırmak için seminerler, paneller ve medya kampanyaları düzenlemek.</li>
                    <li class="wit-item"><b>Sürdürülebilir Bir Etki Yaratmak:</b> Uzun vadeli bir etki yaratmak için işbirlikleri ve ortaklıklar geliştirmek, geri bildirim almak ve programın etkisini düzenli olarak değerlendirmek.</li>
                  </ul>

                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="accordion-container">
          <div className="title-container">Eğitim İçeriği</div>
          <Accordion className="custom-accordion" onChange={handleChange('panel1')}>
            <AccordionSummary className="head" aria-controls="panel1d-content" id="panel1d-header">
              <Typography>Temel Teknolojiler ve Geliştirme Araçları</Typography>
            </AccordionSummary>
            <AccordionDetails className="body">
              <ul className="education-content-list">
                <li className="item">Bash Scripting</li>
                <li className="item">Linux</li>
                <li className="item">Virtualization</li>
                <li className="item">Git (GitLab, GitHub)</li>
                <li className="item">Caching</li>
                <li className="item">Message Brokers</li>
                <li className="item">GitOps</li>
                <li className="item">Docker ve Container</li>
              </ul>
            </AccordionDetails>
          </Accordion>

          <Accordion className="custom-accordion" expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
            <AccordionSummary className="head" aria-controls="panel2d-content" id="panel2d-header">
              <Typography>Modern Uygulama Geliştirme ve DevOps</Typography>
            </AccordionSummary>
            <AccordionDetails className="body">
              <ul className="education-content-list">
                <li className="item">Microservices Design Patterns</li>
                <li className="item">API Development</li>
                <li className="item">Kubernetes</li>
                <li className="item">CI/CD (Jenkins, ArgoCD)</li>
                <li className="item">Application Monitoring</li>
                <li className="item">Secret Management</li>
                <li className="item">Application Scaling</li>
                <li className="item">Logs Management</li>
              </ul>
            </AccordionDetails>
          </Accordion>

          <Accordion className="custom-accordion" expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
            <AccordionSummary className="head" aria-controls="panel3d-content" id="panel3d-header">
              <Typography>Bulut Bilişim ve Yönetimi</Typography>
            </AccordionSummary>
            <AccordionDetails className="body">
              <ul className="education-content-list">
                <li className="item">Infrastructure as a code (Terraform, Crossplane)</li>
                <li className="item">Infrastructure Monitoring and Logging</li>
                <li className="item">Public Cloud Management (AWS, Azure, GCP etc.)</li>
              </ul>
            </AccordionDetails>
          </Accordion>

          <Accordion className="custom-accordion" expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
            <AccordionSummary className="head" aria-controls="panel4d-content" id="panel4d-header">
              <Typography>Ağ Temelleri ve Hizmetleri</Typography>
            </AccordionSummary>
            <AccordionDetails className="body">
              <ul className="education-content-list">
                <li className="item">Kubernetes Networking</li>
                <li className="item">Network Protocols</li>
                <li className="item">Web Server (Nginx)</li>
                <li className="item">Firewall</li>
                <li className="item">Load Balancer</li>
                <li className="item">Ingress</li>
                <li className="item">DNS</li>
              </ul>
            </AccordionDetails>
          </Accordion>

        </div>
        <div className="form-container">
        </div>
        <div className="project-responsible-container">
          <div className="row title">
            Proje Sorumlusu
          </div>
          
          <div className="row project-responsible">


            <div className="avatar-container">
              <div className="avatar-frame">
                <div className="avatar-title">Sena Güneş</div>
                <img src={ProjectManager} alt="" className="avatar" />
              </div>
            </div>


            <div className="about-social-container" >
              <div>
                <div className="row about-title">Hakkında</div>
                <div className="row about-content">Sales & Marketing Executive at ANKASOFT & Women in Tech Project Coordinator </div>
              </div>
              <div className="social-container">
                <span className="social-list">
                  <a target="blank" href="https://www.linkedin.com/in/sena-g%C3%BCne%C5%9F-28s/" className="item">
                    <i className="fa-brands fa-linkedin"></i>
                  </a>
                </span>
              </div>
            </div>
          </div>

        </div>
        <div className="project-footer">
          <div className="logo-container" >
            <img src={WITLogo_black} alt="" className="footer-logo" />
          </div>
        </div>
      </div>
    </div>
  );
}
