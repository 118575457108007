import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import "./servicecard.css";
const pdfName = "product_brochure.pdf";

export default function ServiceCard({ service, isProduct }) {
  const onDownloadBrochureClick = () => {
    fetch(service.brochure_link).then((response) => {
      response.blob().then((blob) => {
        const fileURL = window.URL.createObjectURL(blob);
        let alink = document.createElement("a");
        alink.href = fileURL;
        alink.download = pdfName;
        alink.click();
      });
    });
  };
  return (
    <Fragment>
      <div className="blog-card">
        <div className="meta">
          <div className="photo" style={{ backgroundImage: `url(${service.image_url})` }}></div>
        </div>
        <div className="description">
          <h1>{service.title}</h1>
          <p> {service.description}</p>
          <p className="read-more">
            {isProduct ? (
              <Fragment>
                <Link to={`/demo-request`} style={{ paddingRight: "2%" }}>
                  Demo Request
                </Link>
                <Link to={`/quote`} style={{ paddingRight: "2%" }}>
                  Get a Quote
                </Link>
                <Link onClick={onDownloadBrochureClick} to={`#`} style={{ paddingRight: "2%" }}>
                  Brochure
                </Link>
              </Fragment>
            ) : (
              ""
            )}
            <Link to={service.link}>Read More</Link>
          </p>
        </div>
      </div>
    </Fragment>
  );
}
