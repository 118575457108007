/* eslint-disable jsx-a11y/no-redundant-roles */
import React, { useEffect } from "react";
import { NavLink, Link, useLocation } from "react-router-dom";
import "./sidebar.css";
import Logo from "../../media/images/new-logo.svg";
import $ from "jquery";
//import ReactGa from "react-ga";

export default function SideBar() {
  const location = useLocation();
  
  const getNavLinkClass = (path) => {
    return location.pathname === path
      ? "fh5co-active"
      : location.pathname.includes(path) && path !== "/"
      ? "fh5co-active"
      : "";
  };

  const burgerMenu = (e) => {
    e.preventDefault();
    var $this = $(this);
    if ($("body").hasClass("offcanvas1")) {
      $this.removeClass("active");
      $("body").removeClass("offcanvas1");
    } else {
      $this.addClass("active");
      $("body").addClass("offcanvas1");
    }
  };

  return (
    <React.Fragment>
      <Link
        to={"#"}
        className="js-fh5co-nav-toggle fh5co-nav-toggle"
        onClick={burgerMenu}
      >
        <i></i>
      </Link>
      <aside
        id="fh5co-aside"
        role="complementary"
        className="border js-fullheight"
      >
        <h1 id="fh5co-logo">
          <Link to={"/"}>
            <img className="logo" src={Logo} alt="logo"></img>
          </Link>
        </h1>
        <nav id="fh5co-main-menu" role="navigation">
          <ul>
            <li className={getNavLinkClass("/")}>
              <NavLink to="/">Home</NavLink>
            </li>
            <li className={getNavLinkClass("/services")}>
              <NavLink to="/services">Services</NavLink>
            </li>
            <li className={getNavLinkClass("/products")}>
              <NavLink to="/products">Products</NavLink>
            </li>
            <li className={getNavLinkClass("/stories")}>
              <NavLink to="/stories">Stories</NavLink>
            </li>
            <li className={getNavLinkClass("/blog")}>
              <NavLink to="/blog">Academy</NavLink>
            </li>
            <li className={getNavLinkClass("/women-in-tech")}>
              <NavLink to="/women-in-tech">Women in Tech</NavLink>
            </li>
            <li className={getNavLinkClass("/about")}>
              <NavLink to="/about">About</NavLink>
            </li>
            <li className={getNavLinkClass("/contact")}>
              <NavLink to="/contact">Contact</NavLink>
            </li>
          </ul>
        </nav>

        <div className="fh5co-footer">
          <p >
              <span><Link style={{color:"#404040", fontWeight:'500'}} className="text-decoration-none color" to={'/policy'}>Policy</Link>
              </span>
          </p>
          <p>
            <small>
              <span>&copy; 2023 ANKASOFT. All Rights Reserved.</span>
            </small>
          </p>
          <ul>
            <li>
              <a
                href="https://www.linkedin.com/company/ankasoft/"
                target="_blank"
                rel="noreferrer"
              >
                <i className="fa-brands fa-linkedin"></i>
              </a>
            </li>
            <li>
              <a
                href="https://github.com/anka-software"
                target="_blank"
                rel="noreferrer"
              >
                <i className="fa-brands fa-github"></i>
              </a>
            </li>
            <li>
              <a
                href="https://twitter.com/ankasoft_co"
                target="_blank"
                rel="noreferrer"
              >
                <i className="fa-brands fa-twitter"></i>
              </a>
            </li>
          </ul>
        </div>
      </aside>
    </React.Fragment>
  );
}
