import ServiceCard from "./ServiceCard";
export default function ServiceCardList({ data }) {
  const renderedServices = data.map((service, index) => {
    return (
      <div className="col-md-6 col-padding text-center" key={index}>
        <ServiceCard
          service={service}
          isProduct={service.hasOwnProperty("brochure_link") ? true : false}
        />
      </div>
    );
  });
  return <div className="row">{renderedServices}</div>;
}
