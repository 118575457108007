import React from "react";
import ServiceDisplay from "./ServiceDisplay";
import data from "../serviceData";

export default function ServiceDisplayList() {
  const renderedServices = data.map((service) => {
    return (
      <div className="col-md-6" key={service.title}>
        <ServiceDisplay service={service} />
      </div>
    );
  });
  return <div className="row">{renderedServices}</div>;
}
