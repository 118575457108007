import React from "react";
import parse from "html-react-parser";
import { Link } from "react-router-dom";
import UpboundIcon from "../../../media/images/upbound-icon.png";
import "./productscard.css";
export default function ProductsCardWithButton({ offer, col, icon, color, upboundLink  }) {
  return (
    <div class={`box ${color} col-sm-${col}`}>
      <div>
        <h2>{offer.title ?? ""}</h2>
        <p>{parse(offer.content)}</p>
        <a target="_blank" href={upboundLink} className="product-link-component">
          <div className="product-link-container">
            <div className="product-link">
              <div className="link-icon-container">
                <img src={UpboundIcon} alt="" className="link-icon" />
              </div>
              <span className="link-text">Go to Upbound</span>
            </div>
          </div>
        </a>
      </div>
      <div className="icon-product-box">
        <img src={icon} alt="" />
      </div>
      
    </div>
  );
}
