import React from "react";
import Image from "../../media/images/hybrid.jpg";
import OffersCard from "../../components/offersCard/OffersCard";
import {Helmet} from "react-helmet";
import "./serviceCommon.css";
export default function Service4() {
  const offers = [
    {
      title: "Networking Services",
      content: ` <ul><li>Software Defined Networking (NSX-T) and Micro-Segmentation</li><li>Software Defined Load balancer</li><li>Migration to NSX-T (from Cross platform or NSX-V)</li></ul>`,
    },
    {
      title: "Datacenter Services",
      content: `<ul><li>Design and Deployment of VMware vCloud Foundation (SDDC)</li><li>Development of vRealise Automation - ABX, Workflow, Blueprints</li><li>Design and Deployment of vCloud Director (Service Provider Cloud)</li><li>Upgrades and Migration Services</li><li>Digital Workspace Solutions (Workspace One / Citrix / Azure)</li><li>Design, Deployment and Integration of Ansible and Terraform</li></ul>`,
    },
    {
      title: "Cloud Services",
      content: ` <ul><li>Cloud Advisory</li><li>Design and Deployment of VMC on AWS</li><li>Cloud DevOps, Cloud K8s, Cloud AI/ML</li><li>Cloud Operations and Monitoring</li><li>Cloud Health (Cloud Billing Management)</li><li>Cloud Migration</li></ul>`,
    },
  ];
  return (
    <div id="fh5co-main">
      <Helmet>
      {/* Title */}
      <title>HYBRID INFRASTRUCTURE | ANKASOFT</title>

      {/* Description */}
      <meta name="description" content="Discover our hybrid infrastructure service, seamlessly integrating on-premises and cloud environments to provide a flexible and efficient solution that balances performance and data governance" />

      {/* Keywords (Not: Anahtar kelimeler artık genellikle dikkate alınmaz) */}
      <meta name="keywords" content="Hybrid infrastructure, on-premises, cloud environments, flexible solution, efficient, performance, data governance" />

      {/* Open Graph (og) Etiketleri */}
      <meta property="og:title" content="HYBRID INFRASTRUCTURE | ANKASOFT" />
      <meta property="og:description" content="Discover our hybrid infrastructure service, seamlessly integrating on-premises and cloud environments to provide a flexible and efficient solution that balances performance and data governance" />
      <meta property="og:image" content="https://ankasoft.co/static/media/hybrid_banner.ecf6204ff5e71e160fbc.webp" />
      <meta property="og:url" content="https://ankasoft.co/services/hybrid-infrastructure" />
      <meta property="og:type" content="website" />

    </Helmet>
      <div className="fh5co-narrow-content">
        <div className="row">
          <h3 className="fh5co-heading">HYBRID INFRASTRUCTURE</h3>
          <div className="col-sm-6">
            <img className="img-responsive img-common" src={Image} alt="a" />
          </div>
          <div className="col-sm-6 centered-items">
            <p>
              Hybrid IT combines the right mix of traditional IT, private cloud, and public cloud to
              meet your business and IT goals, so you can integrate new technologies where needed
              and maintain legacy systems where appropriate. ANKASOFT helps its customer in
              delivering modern Hybrid infrastructure solutions by introducing Software Defined Data
              Center (SDDC) based solution to meet their application hosting requirements. This
              eventually brings agility and efficiency in IT operations.
            </p>
          </div>
        </div>
        <h5
          className="centered-items"
          style={{
            fontWeight: 400,
            marginTop: 30,
          }}
        >
          What we offer
        </h5>
        <p>
          An optimized and intelligent hybrid infrastructure is the foundation for digital business.
          Our hybrid infrastructure services maximize value from your IT investments and establish a
          flexible environment to scale, grow and innovate. ANKASOFT broadly classifies its Hybrid
          Infrastructure Services into Networking, Datacenter and Cloud related services.
        </p>
        <div className="container">
          <div className="row">
            <OffersCard offer={offers[0]} col={4} />
            <OffersCard offer={offers[1]} col={4} />
            <OffersCard offer={offers[2]} col={4} />
          </div>
        </div>
      </div>
    </div>
  );
}
