import React from "react";
import {Helmet} from "react-helmet";
import Image from "../../media/images/DevOps.jpg";
import OffersCard from "../../components/offersCard/OffersCard";
import "./serviceCommon.css";

export default function Service3() {
  const offers = [
    {
      title: "New ways of working",
      content: ` <ul>
              <li>Center of Excellence Design and Setup</li>
              <li>Cloud Native Security in DevOps</li>
              <li>Target Operating Model Design (Tools)</li>
              <li>Modern application vision</li>
            </ul>`,
    },
    {
      title: "Tooling and Automation",
      content: `  <ul>
              <li>DevOps Tooling and Automation Assessments</li>
              <li>Deployment Pipeline Simulation, POCs and Implementation</li>
              <li>CI-CD/DevOps Automation</li>
              <li>Continuous Delivery as a Services for modern application delivery</li>
              <li>Kubernetes and Serverless</li>
            </ul>`,
    },
    {
      title: "Architecture Consulting",
      content: `  <ul>
              <li>App Modernization Readiness and Maturity Assessments</li>
              <li>Containerization and Microservices Implementation</li>
              <li>Application Portfolio Rationalization</li>
              <li>Target operating model for Modern infrastructure</li>
            </ul>`,
    },
    {
      title: "Service Improvements",
      content: `   <ul>
              <li>Integrated Program management</li>
              <li>Robust post implementation support</li>
              <li>Adoption support</li>
            </ul>`,
    },
  ];

  return (
    <div id="fh5co-main">
      <Helmet>
      {/* Title */}
      <title>DevOps | ANKASOFT</title>

      {/* Description */}
      <meta name="description" content="Explore our DevOps services page to discover how we streamline software development and IT operations, enhancing collaboration, automation, and efficiency through continuous integration, continuous delivery (CI/CD), and agile methodologies." />

      {/* Keywords (Not: Anahtar kelimeler artık genellikle dikkate alınmaz) */}
      <meta name="keywords" content="DevOps services, software development, IT operations, collaboration, automation, efficiency, continuous integration, continuous delivery (CI/CD), agile methodologies" />

      {/* Open Graph (og) Etiketleri */}
      <meta property="og:title" content="DevOps | ANKASOFT" />
      <meta property="og:description" content="Explore our DevOps services page to discover how we streamline software development and IT operations, enhancing collaboration, automation, and efficiency through continuous integration, continuous delivery (CI/CD), and agile methodologies." />
      <meta property="og:image" content="https://ankasoft.co/static/media/DevOps_banner.d4a67e4fd13995943a2f.jpg" />
      <meta property="og:url" content="https://ankasoft.co/services/devops" />
      <meta property="og:type" content="website" />

    </Helmet>
      <div className="fh5co-narrow-content">
        <div className="row">
          <h3 className="fh5co-heading">DEVOPS</h3>
          <div className="col-sm-6">
            <img className="img-responsive img-common" src={Image} alt="a" />
          </div>
          <div className="col-sm-6 centered-items">
            <p>
              DevOps is a software engineering culture and practice that aims at unifying software
              development (Dev) and software operation (Ops). DevOps is complementary with Agile
              software development. It aims to shorten the systems development life cycle, increased
              deployment frequency, and more dependable releases, in close alignment and provide
              continuous delivery with high software quality.
            </p>
          </div>
        </div>
        <h5
          className="centered-items"
          style={{
            fontWeight: 400,
            marginTop: 30,
          }}
        >
          We offer that making DevOps a way of life
        </h5>
        <div className="container">
          <div className="row">
            <OffersCard offer={offers[0]} col={3} />
            <OffersCard offer={offers[1]} col={3} />
            <OffersCard offer={offers[2]} col={3} />
            <OffersCard offer={offers[3]} col={3} />
          </div>
        </div>
      </div>
    </div>
  );
}
