/* eslint-disable no-useless-escape */
import React, { Fragment, useState } from "react";
import "./contact.css";
import ClipLoader from "react-spinners/ClipLoader";
import InfoMessage from "../../components/alertMessage/alertMessage";
import NetworkService from "../../services/NetworkService";
import Logo from "../../media/images/logo.png";

const regexExpEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

export default function ContactForm({ to, header, text, isDemo, isTkg, isTOss }) {
  const [inputs, setInputs] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);

  const onSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      try {
        setLoading(true);
        document.getElementById("submitBtn").hidden = true;
        Object.assign(inputs, { to: to });
        if (isDemo) {
          Object.assign(inputs, { subject: "Demo Request" });
        }
        if (isTkg) {
          Object.assign(inputs, { subject: "TKG Request" });
        }
        if (isTOss) {
          Object.assign(inputs, { subject: "TanzuOss Request" });
        }
        await NetworkService({ url: "mail", method: "post", data: inputs });
        setSuccess(true);
      } catch (error) {
        setError(error.response.data.message);
      } finally {
        setLoading(false);
        document.getElementById("submitBtn").hidden = false;
        setTimeout(() => {
          setError("");
          setSuccess(false);
        }, 9000);
      }
    }
  };

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
    validateForm();
  };

  const validateForm = () => {
    let formElement = document.getElementById("contact-form").elements;
    let response = true;
    for (let index = 0; index < formElement.length - 1; index++) {
      const element = formElement[index];
      let requiredControl = checkRequired(element, document.getElementById(`${element.id}Validation`));
      if (!requiredControl) {
        response = false;
      }
    }
    return response;
  };

  const checkRequired = (field, fieldValidation) => {
    if (field.value.trim() === "") {
      fieldValidation.innerText = `This field cannot be left blank.`;
      return false;
    }
    if (field.name === "email") {
      const validEmail = regexExpEmail.test(field.value);
      if (!validEmail) {
        fieldValidation.innerText = `This field must be in email format. exp:example@example.com`;
        return false;
      }
    }
    fieldValidation.innerText = "";
    return true;
  };
  return (
    <div className="fh5co-narrow-content animate-box">
      <form action="POST" onSubmit={onSubmit} id="contact-form">
        <div className="row">
          <div className="col-md-12">
            <div className="row">
              <div className="col-md-6">
                <h2>{header}</h2>
                {text}
                <div className="centered">
                  <img className="image-logo" src={Logo} alt="" />
                </div>
              </div>

              <div className="col-md-6" style={isDemo ? { paddingTop: "3%" } : null}>
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Name"
                    name="name"
                    id="name"
                    value={inputs.name || ""}
                    onChange={handleChange}
                  />
                  <p className="validation" id="nameValidation"></p>
                </div>
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Email"
                    name="email"
                    id="email"
                    value={inputs.email || ""}
                    onChange={handleChange}
                  />
                  <p className="validation" id="emailValidation"></p>
                </div>
                {isDemo === true ? (
                  <Fragment> </Fragment>
                ) : (
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Subject"
                      name="subject"
                      id="subject"
                      value={inputs.subject || ""}
                      onChange={handleChange}
                    />
                    <p className="validation" id="subjectValidation"></p>
                  </div>
                )}
                <div className="form-group">
                  <textarea
                    name="message"
                    id="message"
                    cols="30"
                    rows="7"
                    className="form-control"
                    placeholder="Message"
                    value={inputs.message || ""}
                    onChange={handleChange}
                  ></textarea>
                  <p className="validation" id="messageValidation"></p>
                </div>
                <div className="form-group">
                  <input type="submit" className="btn btn-primary btn-md" value="Submit" id="submitBtn" required />
                  <ClipLoader loading={loading} size={40} color="black" />
                </div>
              </div>
              <div className="col-md-6"></div>
            </div>
          </div>
        </div>
      </form>
      {error !== "" ? (
        <InfoMessage message={error} kind={"error"} />
      ) : success === true ? (
        <InfoMessage
          kind={"success"}
          message={"Thank you for contacting us. We will get back to you as soon as possible."}
        />
      ) : (
        ""
      )}
    </div>
  );
}
