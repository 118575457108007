import React from "react";
import { Link } from "react-router-dom";
import "./serviceDisplay.css";

export default function ServiceDisplay({ service }) {
  return (
    <div className="fh5co-feature animate-box">
      <div className="fh5co-icon">
        <i className={service.icon}></i>
      </div>
      <div className="fh5co-text">
        <h3>{service.title}</h3>
        <p>{service.description}</p>
        <Link
          className="btn btn-sm btn-outline-secondary btn-service"
          to={`/services/${service.link}`}
        >
          Learn More<i className="fa-solid fa-arrow-right"></i>
        </Link>
      </div>
    </div>
  );
}
