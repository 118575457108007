import React from "react";
import ServiceCardList from "../../components/service/serviceCard/ServiceCardList";
import data from "../../components/product/productData";
import {Helmet} from "react-helmet";
export default function Product() {
  return (
    <div id="fh5co-main">
      <Helmet>
      {/* Title */}
      <title>Product | ANKASOFT</title>

      {/* Description */}
      <meta name="description" content="our innovative product, designed to revolutionize the way you cost management, providing cost optimization for enhanced less cost." />

      {/* Keywords (Not: Anahtar kelimeler artık genellikle dikkate alınmaz) */}
      <meta name="keywords" content="cost optimization, cloud cost management, finops, provider, crossplane, upbound" />

      {/* Open Graph (og) Etiketleri */}
      <meta property="og:title" content="Product | ANKASOFT" />
      <meta property="og:description" content="our innovative product, designed to revolutionize the way you cost management, providing cost optimization for enhanced less cost." />
      <meta property="og:image" content="https://ankasoft.co/static/media/logo.c446759b3d8f3ed6c4701c8b31f77767.svg" />
      <meta property="og:url" content="https://ankasoft.co/products" />
      <meta property="og:type" content="website" />

    </Helmet>
      <div className="fh5co-narrow-content">
        <h2 className="fh5co-heading animate-box">Products</h2>
        {<ServiceCardList data={data} />}
      </div>
    </div>
  );
}
