/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useLocation, Link } from "react-router-dom";
import NetworkService from "../../services/NetworkService";
import PropagateLoader from "react-spinners/PropagateLoader";
import NotFound from "../NotFound";
import CodeHighlight from "./highlight/CodeHighlight";

export default function BlogDetail() {
  const location = useLocation();
  const path = location.pathname.split("/");

  const postSlug = path[path.length - 1];
  const [post, setPost] = useState({});
  const [owner, setOwner] = useState({});
  const [socials, setSocials] = useState({ linkedin: "", twitter: "", website: "", medium: "" });
  const [spinner, setSpinner] = useState(false);
  const [latestPosts, setLatestPosts] = useState([]);
  useEffect(() => {
    (async () => {
      try {
        setSpinner(true);
        const post = await NetworkService({
          url: "post",
          params: {
            slug: postSlug,
          },
        });

        const user = await NetworkService({
          url: "user",
          params: {
            username: post.data.owner,
          },
        });
        const latestPosts = await NetworkService({
          url: "post/owner",
          params: {
            username: post.data.owner,
          },
        });
        setOwner(user.data);
        setSocials(user.data.socials);
        setPost(post.data);
        setLatestPosts(latestPosts.data);
      } catch (error) {
        setPost({});
      } finally {
        setSpinner(false);
      }
    })();
  }, [postSlug]);

  const pageContent = () => {
    if (spinner) {
      return (
        <div className="container spinner-div">
          <PropagateLoader loading={spinner} size={10} />
        </div>
      );
    } else if (Object.keys(post).length === 0 || Object.keys(owner).length === 0) {
      return <NotFound />;
    } else {
      return renderPage;
    }
  };
  const renderTags = function (tags) {
    if (tags) {
      return tags.map((tag) => {
        return <p key={tag.id}>{tag.text}</p>;
      });
    }
    return;
  };
  const renderLatestPosts = (posts) => {
    return posts.map((post, index) => {
      return (
        <div className="latest-post-aside media" key={index}>
          <div className="lpa-left media-body">
            <div className="lpa-title">
              <Link to={`/blog/${post.slug}`}>
                <h5>{post.title}</h5>
              </Link>
            </div>

            <div className="lpa-meta">
              <label>{owner.name} -</label>
              <label style={{ marginLeft: 5 }}>
                {Date(post.updated_at)
                  .split(" ")
                  .slice(0, 4)
                  .map((item) => item + " ")}
              </label>
            </div>
          </div>
        </div>
      );
    });
  };

  const renderPage = (
    <>
      <div id="fh5co-main">
        <div className="fh5co-narrow-content">
          <div className="blog-single gray-bg">
            <div className="row">
              <div className="col-sm-8">
                <article className="article">
                  <div className="article-title">
                    <h2>{post.title}</h2>
                    <div className="media">
                      <div className="media-body">
                        <label>{post.owner}</label>
                        <span>
                          {Date(post.updated_at)
                            .split(" ")
                            .slice(0, 4)
                            .map((item) => item + " ")}
                        </span>
                      </div>
                    </div>
                  </div>

                  <div className="article-content">
                    <CodeHighlight content={post.content} />
                    <div className="nav tag-cloud">{renderTags(post.tags)}</div>
                  </div>
                </article>
              </div>
              <div className="col-sm-3 blog-aside">
                <div className="widget widget-author">
                  <div className="widget-title">
                    <h3>Author</h3>
                  </div>
                  <div className="widget-body">
                    <div className="media align-items-center">
                      <div className="row justify-content-start centered">
                        <div className="col-md-4 centered">
                          <div className="avatar">
                            <img src={owner.image_url} title="" alt="" />
                          </div>
                        </div>
                        <div className="col-md-8" style={{ margin: 0, padding: 0 }}>
                          <div className="media-body">
                            <h6>{owner.name}</h6>
                          </div>
                        </div>
                      </div>
                    </div>
                    <p>{owner.biography}</p>
                    <div className="socials centered">
                      <p>
                        <a
                          href={socials.medium}
                          className={socials.medium.length === 0 ? "disable-me" : ""}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <i className="fa-brands fa-medium fa-lg"></i>
                        </a>
                        <a
                          href={socials.linkedin}
                          className={socials.linkedin.length === 0 ? "disable-me" : ""}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <i className="fa-brands fa-linkedin-in fa-lg"></i>
                        </a>
                        <a
                          href={socials.twitter}
                          className={socials.twitter.length === 0 ? "disable-me" : ""}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <i className="fa-brands fa-twitter fa-lg"></i>
                        </a>
                        <a
                          href={socials.website}
                          className={socials.website.length === 0 ? "disable-me" : ""}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <i className="fa-solid fa-link fa-lg"></i>
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="widget widget-latest-post">
                  <div className="widget-title">
                    <h3>Latest Posts</h3>
                  </div>
                  <div className="widget-body">{renderLatestPosts(latestPosts)}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );

  return pageContent();
}
