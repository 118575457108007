import React from "react";
import IconJenkins from "../../media/images/provider-jenkins.svg";
import IconCMDB from "../../media/images/provider-cmdb.svg";
import IconJetVra from "../../media/images/provider-jet-vra.svg";
import ProductsCardWithButton from "../../components/product/productCard/ProductsCardWithButton";
import {Helmet} from "react-helmet";
export default function CrossplaneProviders() {
  const offers = [
    {
      title: "Jenkins Provider",
      content: `
            Provider-Jenkins is a minimal Crossplane Provider that is meant to be used as a jenkins for implementing new Providers. It comes with the following features that are meant to be refactored:
            <br/>
            <ul>
              <li>A ProviderConfig type that only points to a credentials Secret.</li>
              <li>A MyType resource type that serves as an example managed resource.</li>
              <li>A managed resource controller reconciles MyType objects and simply prints their configuration in its Observe method.</li>
            </ul>`,
    },
    {
      title: "CMDB Provider",
      content: `
      Provider-CMDB is a minimal Crossplane Provider meant to be used as a template for implementing new Providers. It comes with the following features that are meant to be refactored:
            <br/>
            <ul>
              <li>A ProviderConfig type that only points to a credentials Secret.</li>
              <li>A MyType resource type that serves as an example managed resource.</li>
              <li>A managed resource controller reconciles MyType objects and simply prints their configuration in its Observe method.</li>
            </ul>`,
    },
    {
      title: "VMware Aria Automation Provider",
      content: `
      Provider-jet-vra is the Crossplane infrastructure provider for VMware Aria Automation (vRA). Also, provider-jet-vra is a Crossplane provider built using Terrajet code generation tools and exposes XRM-conformant managed resources for the vRA API.`,
    },
  ];

  return (
    <div id="fh5co-main">
      <Helmet>
      {/* Title */}
      <title>CROSSPLANE PROVIDERS | ANKASOFT</title>

      {/* Description */}
      <meta name="description" content="Crossplane providers offer a simple and efficient way to manage complex infrastructure environments, allowing users to abstract away the complexity and focus on their applications." />

      {/* Keywords (Not: Anahtar kelimeler artık genellikle dikkate alınmaz) */}
      <meta name="keywords" content="jenkins,crossplane, CMDB, VMware Aria, automation" />

      {/* Open Graph (og) Etiketleri */}
      <meta property="og:title" content="CROSSPLANE PROVIDERS | ANKASOFT" />
      <meta property="og:description" content="Crossplane providers offer a simple and efficient way to manage complex infrastructure environments, allowing users to abstract away the complexity and focus on their applications." />
      <meta property="og:image" content="https://ankasoft.co/static/media/crossplane.0700f275f513c275c42c.png" />
      <meta property="og:url" content="https://ankasoft.co/products/crossplane-providers-product" />
      <meta property="og:type" content="website" />

    </Helmet>
      <div className="fh5co-narrow-content">
        <div className="row">
          <h3 className="fh5co-heading">CROSSPLANE PROVIDERS</h3>
          <h5 style={{ marginBottom: "10px" }}>What is Crossplane Providers?</h5>
          <p style={{ marginBottom: "10px" }}>
          Crossplane providers offer a simple and efficient way to manage complex infrastructure environments, allowing users to abstract away the complexity and focus on their applications. As ANKASOFT, we have a bunch of providers for Crossplane. 
          </p>
        </div>
        <div className="container">
          <div className="row justify-content-md-center">
            <ProductsCardWithButton
              offer={offers[0]}
              col={4}
              color="red"
              icon={IconJenkins}
              upboundLink={'https://marketplace.upbound.io/providers/ankasoftco/provider-jenkins/0.0.2'}
            />
            <ProductsCardWithButton
              offer={offers[1]}
              col={4}
              color="blue"
              icon={IconCMDB}
              upboundLink={'https://marketplace.upbound.io/providers/ankasoftco/provider-cmdb/v0.0.1'}
            />
            <ProductsCardWithButton
              offer={offers[2]}
              col={4}
              color="orange"
              icon={IconJetVra}
              upboundLink={'https://marketplace.upbound.io/providers/ankasoftco/provider-jet-vra/v0.0.1'}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
