import React from "react";
import parse from "html-react-parser";
import "./offerscard.css";
export default function OffersCard({ offer, col }) {
  return (
    <div className={`col-md-${col} content-card`}>
      <div className="card-big-shadow">
        <div className="card-offers card-just-text">
          <div className="content">
            <h4 className="title">{offer.title ?? ''}</h4>
            <div className="description">{parse(offer.content)}</div>
          </div>
        </div>
      </div>
    </div>
  );
}
