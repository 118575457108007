import React from "react";
import { Helmet } from "react-helmet";
import Image from "../../media/images/tkg.jpg";
import OffersCard from "../../components/offersCard/OffersCard";
import ContactForm from "../../components/contactForm/contactForm";
import PDF2 from "../../media/brochures/tkg.pdf";
import "./serviceCommon.css";



export default function TKGService() {
  const handleDownload = () => {
    // PDF dosyasının yolunu belirtin
    const pdfPath = PDF2;

    // Geçici bir link oluşturun ve tıklayın
    const link = document.createElement('a');
    link.href = pdfPath;
    link.download = 'tkg-brochures.pdf'; // İndirme dosyasının adını belirtin
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
  const offers = [
    {
      title: "Kubernetes Cluster Management",
      content: `<p>TKG enables you to
easily create and manage Kubernetes clusters.</p>`,
    },
    {
      title: "Security and Compliance",
      content: `<p>Ensures that security policies
are implemented and compliance requirements are met.
This allows applications to run and be managed securely.</p>`,
    },
    {
      title: "Automation and Simplified Operations",
      content: `<p>TKG automates
and simplifies Kubernetes operations. Operations such as
updates, backups and security patches are managed from a
single centralized location.</p>`,
    },
    {
      title: "Multi-Cloud and Hybrid Cloud Support",
      content: `<p>Provides the
ability to run Kubernetes clusters both in on-premises data
centers (on-premises) and on various cloud providers
(AWS, Azure, Google Cloud, Huawei Cloud). This
flexibility makes it easy to move and manage applications
between different environments.</p>`,
    },
  ];

  return (
    <div id="fh5co-main">
      <Helmet>
        {/* Title */}
        <title>TKG Service - Promotion | ANKASOFT</title>

        {/* Description */}
        <meta name="description" content="Optimize Kubernetes Cluster Management with VMware Tanzu Kubernetes Grid (TKG). Automate operations, ensure security and compliance, and achieve seamless multi-cloud and hybrid cloud support. Ideal for scalable, efficient, and secure application development." />

        {/* Keywords (Not: Anahtar kelimeler artık genellikle dikkate alınmaz) */}
        <meta name="keywords" content="DevOps services, software development, IT operations, collaboration, automation, efficiency, continuous integration, continuous delivery (CI/CD), agile methodologies" />

        {/* Open Graph (og) Etiketleri */}
        <meta property="og:title" content="TKG Service - Promotion | ANKASOFT" />
        <meta property="og:description" content="Optimize Kubernetes Cluster Management with VMware Tanzu Kubernetes Grid (TKG). Automate operations, ensure security and compliance, and achieve seamless multi-cloud and hybrid cloud support. Ideal for scalable, efficient, and secure application development." />
        <meta property="og:image" content="https://ankasoft.co/static/media/DevOps_banner.d4a67e4fd13995943a2f.jpg" />
        <meta property="og:url" content="https://ankasoft.co/services/tkg-service-promotion" />
        <meta property="og:type" content="website" />

      </Helmet>
      <div className="fh5co-narrow-content">
        <div className="row">
          <h3 className="fh5co-heading">TKG Service - Promotion</h3>
          <div className="col-sm-6">
            <img className="img-responsive img-common" src={Image} alt="a" />
          </div>
          <div className="col-sm-6 centered-items">

            <p>
              <h3 className="text-center mb-3 text-bold">What is TKG ?</h3>
              Tanzu Kubernetes Grid allows you to deploy, manage and scale
              your Kubernetes-based applications quickly and efficiently. With
              its security and compliance features, along with multi-cloud and
              hybrid cloud support, TKG is an indispensable part of modern
              application development and management processes.

            </p>
          </div>
        </div>
        <h5
          className="centered-items"
          style={{
            fontWeight: 600,
            marginTop: 30,
            fontSize: "1.5rem",
          }}
        >
          Key Benefits
        </h5>
        <div className="container">
          <div className="row">
            <OffersCard offer={offers[0]} col={3} />
            <OffersCard offer={offers[1]} col={3} />
            <OffersCard offer={offers[2]} col={3} />
            <OffersCard offer={offers[3]} col={3} />
          </div>
        </div>
        <div className="container">
          <h2>TKG Customer Use Case</h2>
          <p>An e-commerce company wants to build a cloud-based, scalable and secure e-commerce platform to meet growing customer demands
            and maintain a competitive advantage. The company wants to modernize its existing infrastructure to move to a microservices
            architecture and manage its applications using Kubernetes. In this scenario, the e-commerce company builds a modern and scalable
            application infrastructure using VMware Tanzu Kubernetes Grid. TKG simplifies, automates and secures the management of
            Kubernetes clusters in multi-cloud and hybrid cloud environments. This enables the company to respond quickly to customer
            requests, optimize application performance and ensure a secure operating environment.</p>
        </div>
        <div className="container mt-4">
          <h4>Outcomes</h4>
          <ul>
            <li>Increased Efficiency and Speed</li>
            <li>Higher Scalability</li>
            <li>Enhanced Security and Compliance</li>
            <li>Cost Savings</li>
            <li>Business Continuity and Resilience</li>
            <li>Enhanced User Experience</li>
          </ul>
        </div>
        <div className="container mt-5" style={{display:'flex', alignItems:"flex-start", flexDirection:"column"}}>
          <h4>Brochures</h4>
          <button className="p-2 btn  btn-md btn-primary btn-outline"   onClick={handleDownload}>
            Download the brochures
          </button>
        </div>
        <div className="container" style={{ paddingTop: "5%" }}>
          <ContactForm
            text="Please fill out the form and we will get back to you as soon as possible."
            header="Ask For a TKG Service Promotion"
            to="sales@ankasoft.co"
            isTkg={true}
          />
        </div>
        
      </div>


    </div>
  );
}
