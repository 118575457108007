/* eslint-disable no-useless-escape */
import React, { useState } from "react";
import ContactForm from "../../components/contactForm/contactForm";

export default function Quote() {
  return (
    <div id="fh5co-main">
      <div className="container" style={{ paddingTop: "5%" }}>
        <ContactForm
          text="Give us a call or drop by anytime, we endeavour to answer all
                enquiries within 24 hours on business days. We will be happy to
                answer your questions."
          header="Get A Quote"
          to="sales@ankasoft.co"
        />
      </div>
    </div>
  );
}
