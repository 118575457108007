import ImageOrphia from "../../media/images/orphia_logo.png";
import BrochureOrphia from "../../media/brochures/orphia_brochure.pdf";
import CrossplaneLogo from "../../media/images/crossplane.png";

const products = [
  {
    title: "ORPHIA",
    description:
      "ANKASOFT Orphia is an event-driven state management application designed using modern microservice infrastructure. It increases productivity and efficiency while reducing costs and complexity.",
    image_url: ImageOrphia,
    link: "orphia-product",
    brochure_link: BrochureOrphia,
    icon: "fa-solid fa-infinity",
    demoVisibility:true,
    quoteVisibility:true,
    brochureVisibility:true,
  },
  {
    title: "CROSSPLANE PROVIDERS",
    description:
      "Crossplane providers offer a simple and efficient way to manage complex infrastructure environments, allowing users to abstract away the complexity and focus on their applications. As ANKASOFT, we have a bunch of providers for Crossplane.",
    image_url: CrossplaneLogo,
    link: "crossplane-providers-product",
    icon: "fa-solid fa-infinity",
  },
];

export default products;
