import React from "react";
import Image from "../../media/images/techconsulting.jpg";
import OffersCard from "../../components/offersCard/OffersCard";
import {Helmet} from "react-helmet";

import "./serviceCommon.css";

export default function Service6() {
  const offers = [
    {
      title: "Cloud Native Application Consulting",
      content: ` Cloud-native is the future of application development, with massive potential for business impact-the ability to move an idea into production quickly and efficiently.`,
    },
    {
      title: "Cloud Native Infrastructure Consulting",
      content: ` Containers & Kubernetes are the foundation for building cloud native infrastructure to improve software velocity & developer productivity. We offer strategic consulting on defining the right infrastructure operating model for modernised application.`,
    },
    {
      title: "Microservices Architecture Consulting",
      content: `  Our experts can help you in assessing your monolithic apps and recommend target microservices architecture. We develop strategies that suit your business requirements and incrementally move your app to microservices-based architecture.`,
    },
    {
      title: "Agile DevOps and DevSecOps Consulting",
      content: ` Consultants will understand your existing development process and tools, align the same with your business and technology transformation roadmap to provide a target set of tools and process to meet your business objectives.`,
    },
    {
      title: "Hybrid Cloud and Automation Consulting",
      content: ` Tech-leaders must adopt multicloud services and hybrid operating models. Multicloud strategies entail the adoption & management of multiple clouds and on-premises services for business outcomes. Our experts understand the infrastructure and define a strategy for adopting multicloud solutions.`,
    },
  ];
  return (
    <div id="fh5co-main">
      <Helmet>
      {/* Title */}
      <title>TECHNOLOGY CONSULTING | ANKASOFT</title>

      {/* Description */}
      <meta name="description" content="Explore our technology consulting service, where our experts provide strategic guidance and tailored solutions to address your organization's unique technological challenges and opportunities." />

      {/* Keywords (Not: Anahtar kelimeler artık genellikle dikkate alınmaz) */}
      <meta name="keywords" content="Technology consulting, experts, strategic guidance, tailored solutions, technological challenges, opportunities" />

      {/* Open Graph (og) Etiketleri */}
      <meta property="og:title" content="TECHNOLOGY CONSULTING | ANKASOFT" />
      <meta property="og:description" content="Explore our technology consulting service, where our experts provide strategic guidance and tailored solutions to address your organization's unique technological challenges and opportunities." />
      <meta property="og:image" content="https://ankasoft.co/static/media/consulting_banner.5a5951a6e9a245cbb73d.jpg" />
      <meta property="og:url" content="https://ankasoft.co/services/technology-consulting" />
      <meta property="og:type" content="website" />

    </Helmet>
      <div className="fh5co-narrow-content">
        <div className="row">
          <h3 className="fh5co-heading">TECHNOLOGY CONSULTING</h3>
          <div className="col-sm-6">
            <img className="img-responsive img-common" src={Image} alt="a" />
          </div>
          <div className="col-sm-6 centered-items">
            <p>
              Enterprises needs to consistently invest in technology and human resources in order to
              keep their existing IT infrastructure running, increase and strengthen efficiency in
              the world of rapidly growing technology world. We help our clients think and do,
              delivering technology strategy around cloud native solutions that gives them the
              confidence to take on their toughest challenges and improve the lives of their people
              and customers.
            </p>
          </div>
        </div>
        <h5
          className="centered-items"
          style={{
            fontWeight: 400,
            marginTop: 30,
          }}
        >
          What we offer
        </h5>
        <div className="container">
          <div className="row">
            <OffersCard offer={offers[0]} col={4} />
            <OffersCard offer={offers[1]} col={4} />
            <OffersCard offer={offers[2]} col={4} />
            <OffersCard offer={offers[3]} col={6} />
            <OffersCard offer={offers[4]} col={6} />
          </div>
        </div>
      </div>
    </div>
  );
}
