import React from "react";
import Image from "../../media/images/cloud-native.jpg";
import {Helmet} from "react-helmet";
import OffersCard from "../../components/offersCard/OffersCard";
import "./serviceCommon.css";

export default function Service2() {
  const offers = [
    {
      title: "Cloud Native Application Assessment",
      content: ` A comprehensive Application Modernization assessment to uncover the existing
              application landscape, legacy services, dependencies and provide a report on the
              journey toward modernizing platform, application and security solutions.`,
    },
    {
      title: "Application Modernization",
      content: ` <p>ANKASOFT offers a comprehensive approach in delivering modernised aplication and infrastructure assisting companies to achieve desired digital transformation.</p><p>We address two key offerings:</p>
    <h5>Kubernetes Services</h5>
    ANKASOFT ensures efficient Kubernetes implementation within your business. By providing expert advice on adopting an efficient container orchestrator. 
     <h5>DevOps and DevSecOps Services</h5>
    Increase DevOps agility, shorten releases, improve reliability and stay ahead of the
    competition with DevOps tools. ANKASOFT has the agile practices, cloud native tools
    and DevOps tools you need to help improve the entire DevOps lifecycle, from the build, run and manage applications.
`,
    },
  ];

  return (
    <div id="fh5co-main">
      <Helmet>
      {/* Title */}
      <title>Cloud Native | ANKASOFT</title>

      {/* Description */}
      <meta name="description" content="our cloud-native services, where we design and develop applications specifically for cloud environments, utilizing microservices, containerization, and dynamic orchestration for optimal scalability and resilience." />

      {/* Keywords (Not: Anahtar kelimeler artık genellikle dikkate alınmaz) */}
      <meta name="keywords" content="Cloud-native services, applications, cloud environments, microservices, containerization, dynamic orchestration, scalability, resilience" />

      {/* Open Graph (og) Etiketleri */}
      <meta property="og:title" content="Cloud Native | ANKASOFT" />
      <meta property="og:description" content="our cloud-native services, where we design and develop applications specifically for cloud environments, utilizing microservices, containerization, and dynamic orchestration for optimal scalability and resilience." />
      <meta property="og:image" content="https://ankasoft.co/static/media/cloud-native_banner.5f92b877264cfc33c5db.jpg" />
      <meta property="og:url" content="https://ankasoft.co/services/cloud-native" />
      <meta property="og:type" content="website" />

    </Helmet>
      <div className="fh5co-narrow-content">
        <div className="row">
          <h3 className="fh5co-heading">CLOUD NATIVE</h3>
          <div className="col-sm-6">
            <img className="img-responsive img-common" src={Image} alt="a" />
          </div>
          <div className="col-sm-6 centered-items">
            <p>
              Cloud native computing is an approach in software development that utilizes cloud
              computing to "build and run scalable applications in modern, dynamic environments such
              as public, private, and hybrid clouds". A cloud native environment lets you take
              complete advantage of digital & cognitive capabilities. From idea to design to
              implementation to management, ANKASOFT assists sharpen your cloud native based
              innovative solution. Create a cloud native culture & roadmap through the ANKASOFT
              DevOps Garage & InnovApp framework & enhance application experience with
              microservices, Kubernetes, & DevSecOps.
            </p>
          </div>
        </div>
        <h5
          className="centered-items"
          style={{
            fontWeight: 400,
            marginTop: 30,
          }}
        >
          What we offer
        </h5>
        <div className="container">
          <div className="row justify-content-center">
            <OffersCard offer={offers[0]} col={6} />
            <OffersCard offer={offers[1]} col={7} />
          </div>
        </div>
      </div>
    </div>
  );
}
