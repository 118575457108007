import React from "react";
import "./alertmessage.css";
export default function InfoMessage({ message, kind }) {
  const error = (
    <div className="error-msg">
      <i className="fa fa-times-circle"></i>
      {message}
    </div>
  );
  const success = (
    <div className="success-msg">
      <i className="fa fa-check"></i>
      {message}
    </div>
  );
  const warning = (
    <div className="warning-msg">
      <i className="fa fa-warning"></i>
      {message}
    </div>
  );

  return (
    <div className="container-fluid msg">
      {kind === "warning" ? warning : kind === "success" ? success : error}
    </div>
  );
}
