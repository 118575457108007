import React from "react";
import Image1 from "../../media/images/manage-service-workflow.png";
import Image2 from "../../media/images/ticket-system.PNG";
import Image3 from "../../media/images/team-experience.PNG";
import Image4 from "../../media/images/manage-service-access-methodology.PNG";
import {Helmet} from "react-helmet";

import OffersCard from "../../components/offersCard/OffersCard";
import "./serviceCommon.css";

export default function Service7() {
  return (
    <div id="fh5co-main">
      <Helmet>
      {/* Title */}
      <title>Manage Service | ANKASOFT</title>

      {/* Description */}
      <meta name="description" content="Experience our managed services offering, where we handle the end-to-end management and optimization of your IT infrastructure, ensuring reliability and performance while you focus on core business activities" />

      {/* Keywords (Not: Anahtar kelimeler artık genellikle dikkate alınmaz) */}
      <meta name="keywords" content="Managed services, end-to-end management, optimization, IT infrastructure, reliability, performance, core business activities" />

      {/* Open Graph (og) Etiketleri */}
      <meta property="og:title" content="Manage Service | ANKASOFT" />
      <meta property="og:description" content="Experience our managed services offering, where we handle the end-to-end management and optimization of your IT infrastructure, ensuring reliability and performance while you focus on core business activities" />
      <meta property="og:image" content="https://ankasoft.co/static/media/manage-service-logo.e938421b9047189b4c0d.jpg" />
      <meta property="og:url" content="https://ankasoft.co/services/manage-service" />
      <meta property="og:type" content="website" />

    </Helmet>
      <div className="fh5co-narrow-content">
        <div className="row d-flex flex-column align-items-center">
          <h3 className="fh5co-heading">Manage Service</h3>
          <div className="col-sm-6">
            <img className="img-responsive mb-5" src={Image1} alt="a" />
          </div>
          <div className="col-sm-6">
            <img className="img-responsive mb-5" src={Image2} alt="a" />
          </div>
          <div className="col-sm-6">
            <img className="img-responsive mb-5" src={Image3} alt="a" />
          </div>
          <div className="col-sm-6">
            <img className="img-responsive mb-5" src={Image4} alt="a" />
          </div>
        </div>
      </div>
    </div>
  );
}
