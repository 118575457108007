import React from "react";
import ServiceCardList from "../../components/service/serviceCard/ServiceCardList";
import data from "../../components/stories/storiesData";
import {Helmet} from "react-helmet";
export default function Stories() {
  return (
    <div id="fh5co-main">
      <Helmet>
      {/* Title */}
      <title>Stories | ANKASOFT</title>

      <meta name="description" content="A section featuring success stories of ANKASOFT." />

      <meta name="keywords" content="success stories, project achievements, Ankasoft stories" />

      <meta property="og:title" content="Stories | ANKASOFT" />
      <meta property="og:description" content="A section featuring success stories of ANKASOFT." />
      <meta property="og:image" content="https://ankasoft.co/static/media/logo.c446759b3d8f3ed6c4701c8b31f77767.svg" />
      <meta property="og:url" content="https://ankasoft.co/stories" />
      <meta property="og:type" content="website" />

    </Helmet>
      <div className="fh5co-narrow-content">
        <h2 className="fh5co-heading animate-box">Stories</h2>
        {<ServiceCardList data={data} />}
      </div>
    </div>
  );
}
