import React from "react";

export default function NotFound() {
  return (
    <div id="fh5co-main">
      <div
        className="fh5co-narrow-content"
        style={{ alignItems: "center", justifyContent: "center", display: "flex" }}
      >
        <h3>Page Not Found</h3>
      </div>
    </div>
  );
}
