import React from "react";
import { Link } from "react-router-dom";
import Image from "../../media/images/about.png";
import {Helmet} from "react-helmet";

import ServiceDisplayList from "../../components/service/serviceDisplay/ServiceDisplayList";
export default function About() {
  return (
    <div id="fh5co-main">
      <Helmet>
      {/* Title */}
      <title>About | ANKASOFT</title>

      {/* Description */}
      <meta name="description" content="ANKASOFT is a software and technology consulting business that collaborates with global enterprises to develop, manage, and safeguard multi-cloud applications, offering strategic expertise, execution experience, and comprehensive IT services, including system integration and virtualization, to enhance clients' competitiveness and flexibility in the industry." />

      {/* Keywords (Not: Anahtar kelimeler artık genellikle dikkate alınmaz) */}
      <meta name="keywords" content="applications, strategic thinking, subject matter expertise, execution experience, corporate solutions, flexibility, business continuity, efficiency, system integration, information and communication technology services" />

      {/* Open Graph (og) Etiketleri */}
      <meta property="og:title" content="About | ANKASOFT" />
      <meta property="og:description" content="ANKASOFT is a software and technology consulting business that collaborates with global enterprises to develop, manage, and safeguard multi-cloud applications, offering strategic expertise, execution experience, and comprehensive IT services, including system integration and virtualization, to enhance clients' competitiveness and flexibility in the industry." />
      <meta property="og:image" content="https://ankasoft.co/static/media/logo.c446759b3d8f3ed6c4701c8b31f77767.svg" />
      <meta property="og:url" content="https://ankasoft.co/about" />
      <meta property="og:type" content="website" />

    </Helmet>
      <div className="fh5co-narrow-content" style={{ paddingBottom: 0 }}>
        <div className="row">
          <div className="col-md-6" style={{ alignSelf: "end" }}>
            <img
              className="img-responsive center-block"
              src={Image}
              alt=""
              width={650}
              style={{ marginBottom: 30 }}
            />
          </div>
          <div className="col-md-6">
            <h2 className="fh5co-heading">About US</h2>
            <p style={{ opacity: 0.6 }}>
              ANKASOFT, a software and technology consulting and services business, works with
              worldwide enterprises to create, administer, manage, and protect multi-cloud
              applications. We provide a unique combination of strategic thinking, subject matter
              expertise, and years of execution experience. Also, with its corporate solutions,
              ANKASOFT aims to give its customers flexibility, business continuity, and efficiency.
              ANKASOFT, including complete system integration, offer information and communication
              technology services.
            </p>
            <p style={{ opacity: 0.6 }}>
              With the help of virtualization, security, storage architecture, cloud computing, IT
              integration services, IT solutions, consultation, and data center solutions, ANKASOFT,
              founded in 2018, best prepares its clients for the competitive environment. With the
              expertise, experience, and references it has gathered, ANKASOFT, which has garnered
              notoriety for its cutting-edge solutions and virtualization technology projects,
              particularly in recent years, has attained a level where it can compete with the
              industry's top players. ANKASOFT uses its resources with qualified staff to offer all
              services. In addition to providing the most precise answer to its clients regarding
              the institutions' IT demands without relying on a single source, ANKASOFT also offers
              practical technical training in IT projects as required by its qualified trainers.
            </p>
            <p style={{ opacity: 0.6 }}>
              {" "}
              Since its creation, ANKASOFT has consistently incorporated new technology; it is an
              expert in sectoral solutions. Our company's approach to sectoral development has also
              benefited from working problem-oriented rather than brand-oriented.
            </p>
          </div>
        </div>
      </div>

      <div className="fh5co-narrow-content">
        <h2 className="fh5co-heading animate-box">Our Services</h2>
        <ServiceDisplayList />
      </div>

      <div className="fh5co-narrow-content">
        <div className="row">
          <div className="col-md-4 animate-box">
            <h1 className="fh5co-heading-colored">Get in touch</h1>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 col-md-offset-3 col-md-pull-3">
            <h6 className="fh5co-lead">
              Give us a call or drop by anytime, we endeavour to answer all enquiries within 24
              hours on business days. We will be happy to answer your questions.
            </h6>
            <p>
              <Link to={"/contact"} className="btn btn-primary">
                Learn More
              </Link>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
