import ImageDevOps from "../../media/images/DevOps_banner.jpg";
import ImageNative from "../../media/images/cloud-native_banner.jpg";
import ImageSRE from "../../media/images/sre_banner.jpg";
import ImageAutomation from "../../media/images/automation_banner.png";
import ImageHybrid from "../../media/images/hybrid_banner.webp";
import ImageConsulting from "../../media/images/consulting_banner.jpg";
import ImageConsulting1 from "../../media/images/manage-service-logo.jpg";
import FinopsConsultancyImage from "../../media/images/ServiceFinopsConsultancy.png";
import CMSS from "../../media/images/ServiceCMCSS.jpeg";
import ImageTKG from "../../media/images/tkg.jpg";
import ImageOSS from "../../media/images/tanzu-oss.jpeg";
const services = [
  {
    title: "DEVOPS",
    description:
      "DevOps is a software engineering culture and practice that aims at unifying software development (Dev) and software operation (Ops).",
    image_url: ImageDevOps,
    link: "devops",
    icon: "fa-solid fa-infinity",
  },
  {
    title: "CLOUD NATIVE",
    description:
      "From idea to design to implementation to management, ANKASOFT assists sharpen your cloud native based innovative solution.",
    image_url: ImageNative,
    link: "cloud-native",
    icon: "fa-solid fa-cloud",
  },
  {
    title: "SITE RELIABILITY ENGINEERING",
    description:
      "SRE is what you get when you treat operations as if it’s a software problem. ANKASOFT's mission is to protect, provide for, and progress the software and system.",
    image_url: ImageSRE,
    link: "sre",
    icon: "fa-solid fa-laptop-code",
  },
  {
    title: "CLOUD AUTOMATION & MANAGEMENT",
    description:
      "ANKASOFT Cloud Solutions powered by Azure, AWS, VMware and Google Cloud Platform enable companies to focus on their on-demand IT infrastructure platforms.",
    image_url: ImageAutomation,
    link: "cloud-automation-management",
    icon: "fa-solid fa-cloud-meatball",
  },
  {
    title: "HYBRID INFRASTRUCTURE",
    description:
      "ANKASOFT helps it's customer in delivering modern Hybrid infrastructure solutions by introducing Software Defined Data Center (SDDC) based solution to meet their application hosting requirements.",
    image_url: ImageHybrid,
    link: "hybrid-infrastructure",
    icon: "fa-solid fa-diagram-project",
  },
  {
    title: "TECHNOLOGY CONSULTING",
    description:
      "ANKASOFT helps it's clients think and do, delivering technology strategy around cloud native solutions that gives them the confidence to take on their toughest challenges and improve the lives of their people and customers.",
    image_url: ImageConsulting,
    link: "technology-consulting",
    icon: "fa-solid fa-person-chalkboard",
  },
  {
    title: "MANAGE SERVICE",
    description:
      "ANKASOFT can support you with some of this burden by helping you manage your most complex infrastructure, freeing you to focus on growing your business and delighting your customers.",
    image_url: ImageConsulting1,
    link: "manage-service",
    icon: "fa-solid fa-person-chalkboard",
  },
  {
    title: "FINOPS CONSULTANCY",
    description:
      "Empower your cloud journey with FinOps Assessment Service, find out your FinOps Maturity Score, identify cloud optimization opportunities, boost performance and unlock the true value of your cloud investment.",
    image_url: FinopsConsultancyImage,
    link: "finops-consultancy",
    icon: "fa-solid fa-person-chalkboard",
  },
  {
    title: "CLOUD GAMING SOLUTION",
    description:
      "The gaming industry is a constantly evolving sector, and in this dynamic environment, game development firms require expert guidance to navigate the challenges they may encounter when transitioning to cloud technologies. That's where we come in.",
    image_url: CMSS,
    link: "cloud-migration-consultancy-and-support-services",
    icon: "fa-solid fa-person-chalkboard",
  },
  {
    title: "TKG SERVICE - PROMOTION",
    description:
      "Tanzu Kubernetes Grid allows you to deploy, manage and scale your Kubernetes-based applications quickly and efficiently. With its security and compliance features, along with multi-cloud and hybrid cloud support, TKG is an indispensable part of modern application development and management processes.",
    image_url: ImageTKG,
    link: "tkg-service-promotion",
    icon: "fa-solid fa-person-chalkboard",
  },
  {
    title: "Tanzu OSS Service",
    description:
      "Tanzu OSS is a VMware tool that helps organizations manage and secure open-source components by scanning Kubernetes environments, Helm repositories, and Docker images to detect security vulnerabilities, misconfigurations, and compliance issues. It provides comprehensive vulnerability detection, configuration analysis, and supports enterprise-grade container images for secure Kubernetes deployments.",
    image_url: ImageOSS,  // Tanzu OSS ile ilgili uygun bir görselin yolu
    link: "tanzu-oss",
    icon: "fa-solid fa-shield-alt",
}

];

export default services;
