import React, { Component } from "react";
import hljs from "highlight.js";
import parse from "html-react-parser";
import "./highlight.css";
export default class Preview extends Component {
  componentDidMount() {
    this.updateCodeSyntaxHighlighting();
  }

  componentDidUpdate() {
    this.updateCodeSyntaxHighlighting();
  }

  updateCodeSyntaxHighlighting = () => {
    document.querySelectorAll("pre code").forEach((block) => {
      hljs.highlightElement(block);
    });
  };

  render() {
    return <div className="article-content">{parse(String(this.props.content))}</div>;
  }
}
