import React from "react";
import IconKey from "../../media/images/gold-key.png";
import IconKarma from "../../media/images/icon-karma.svg";
import ProductsCard from "../../components/product/productCard/ProductsCard";
import {Helmet} from "react-helmet";
export default function TestProduct() {
  const offers = [
    {
      title: "Key Benefits",
      content: ` <ul>
              <li>Reduce Cost - You can turn off virtual machines when not in use, 
              avoiding unnecessary costs and making your cloud environment more efficient.</li>
              <li>Simple to use - allows you to easily and quickly manage and automate your
               VMs in your Public Cloud environments.</li>
              <li>Automation - automating routine tasks that IT and DevOps engineers 
              regularly perform reduces labor and time costs.
              </li>
              <li>Multi-cloud support - allows you to manage your VMs in Public Cloud environments from a single platform.
              </li>
            </ul>`,
    },
    {
      title: "Customer Use Cases",
      content: ` <ul>
              With ANKASOFT Orphia, you can manage your virtual machines in your Public Cloud environments from a single platform and temporarily shut them off when they are not in use. 
              You can also schedule usage periods to automate your operations. 
              <p></p>
              Orphia can help you save up to 82% on your cloud costs, allowing you to use your resources more efficiently and have a more organized cloud environment.
            </ul>`,
    },
  ];

  return (
    <div id="fh5co-main">
      <Helmet>
      {/* Title */}
      <title>Orphia | ANKASOFT</title>

      {/* Description */}
      <meta name="description" content="Orphia is an event-driven state management application designed using modern microservice infrastructure." />

      {/* Keywords (Not: Anahtar kelimeler artık genellikle dikkate alınmaz) */}
      <meta name="keywords" content="cost management, cost optimization, time-based coset" />

      {/* Open Graph (og) Etiketleri */}
      <meta property="og:title" content="Orphia | ANKASOFT" />
      <meta property="og:description" content="Orphia is an event-driven state management application designed using modern microservice infrastructure." />
      <meta property="og:image" content="https://ankasoft.co/static/media/orphia_logo.14cc777c20410da74e5c.png" />
      <meta property="og:url" content="https://ankasoft.co/products/orphia-product" />
      <meta property="og:type" content="website" />

    </Helmet>
      <div className="fh5co-narrow-content">
        <div className="row">
          <h3 className="fh5co-heading">ORPHIA</h3>
          <h5 style={{ marginBottom: "10px" }}>What is Orphia?</h5>
          <p style={{ marginBottom: "10px" }}>
            ANKASOFT Orphia is an event-driven state management application
            designed using modern microservice infrastructure. It increases
            productivity and efficiency while reducing costs and complexity. The
            automation provided to IT and DevOps Engineers allows you to manage
            your VMs in all cloud environments with less effort and without
            wasting time.
          </p>
        </div>
        <div className="container">
          <div className="row justify-content-md-center">
            <ProductsCard
              offer={offers[0]}
              col={4}
              color="red"
              icon={IconKey}
            />
            <ProductsCard
              offer={offers[1]}
              col={4}
              color="blue"
              icon={IconKarma}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
