import React from "react";
import parse from "html-react-parser";
import "./productscard.css";
export default function ProductsCard({ offer, col, icon, color }) {
  return (
    <div class={`box ${color} col-sm-${col}`}>
      <div>
        <h2>{offer.title ?? ""}</h2>
        <p>{parse(offer.content)}</p>
      </div>
      <div className="icon-product-box">
        <img src={icon} alt="" />
      </div>
    </div>
  );
}
