import React from "react";
import { Link } from "react-router-dom";
import "./blogcard.css";

function convert(date) {
  date = new Date(date);
  return date
    .toString()
    .split(" ")
    .slice(0, 4)
    .map((item) => item + " ");
}

export default function BlogCard({ post }) {
  const regex = /(<([^>]+)>)/gi;
  const shortDesc = post.content.replace(regex, "");
  return (
    <Link
      to={`/blog/${post.slug}`}
      style={{ textDecoration: "none" }}
      className="blog-entry h-100"
      state={"s"}
    >
      <div className="blog-img">
        <img
          src={post.image_url}
          className="img-responsive blog-img"
          style={{
            objectFit: "cover",
            height: "250px",
          }}
          alt="co"
        />
      </div>
      <div className="desc">
        <h3>
          {post.title.slice(0, 52)} {post.title.length > 52 ? "..." : ""}
        </h3>
        <span>
          <small>by {post.owner} </small>
          <small>{convert(post.updated_at)}</small>
        </span>
        <p>
          {shortDesc.slice(0, 140)} {post.content.length > 140 ? "..." : ""}
        </p>
      </div>
    </Link>
  );
}
