import React from "react";
import { Helmet } from "react-helmet";
import OffersCard from "../../components/offersCard/OffersCard";
import Image from "../../media/images/tanzu-oss.jpeg";
import ContactForm from "../../components/contactForm/contactForm";

import PDF2 from "../../media/brochures/tanzu-oss.pdf";
import "./serviceCommon.css";

export default function TanzuOSSService() {
  const handleDownload = () => {
    const pdfPath = PDF2;

    // Create a temporary link element and click to trigger download
    const link = document.createElement('a');
    link.href = pdfPath;
    link.download = 'tanzu-oss-health-assessment.pdf';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const offers = [
    {
      title: "Comprehensive Vulnerability Detection",
      content: `<p>Tanzu OSS scans open-source packages in Helm repositories, Docker images, and Kubernetes environments to detect security vulnerabilities, 
        providing detailed CVE codes and implications for each vulnerability.</p>`,
    },
    {
      title: "Configuration and Compliance Analysis",
      content: `<p>Assesses Kubernetes configurations against best practices and generates compliance scores based on globally recognized security frameworks.</p>`,
    },
    {
      title: "Support for Enterprise-Grade Container Images",
      content: `<p>Integrates with the Tanzu Application Catalog (TAC) to provide enterprise-ready container images that are pre-packaged and scanned 
        according to international security standards.</p>`,
    },
    {
      title: "Automated Security Posture Management",
      content: `<p>Continuously monitors and reports on the security posture of Kubernetes clusters, providing JSON-formatted reports that highlight vulnerabilities 
        and configuration issues.</p>`,
    },
  ];

  return (
    <div id="fh5co-main">
      <Helmet>
        {/* Title */}
        <title>Tanzu OSS Service - Kubernetes Security Report | ANKASOFT</title>

        {/* Description */}
        <meta name="description" content="Optimize Kubernetes security and compliance with VMware Tanzu OSS. Detect vulnerabilities, manage configurations, and support enterprise-grade container images with Tanzu OSS Health Assessment." />

        {/* Open Graph (og) Tags */}
        <meta property="og:title" content="Tanzu OSS Service - Kubernetes Security Report | ANKASOFT" />
        <meta property="og:description" content="Optimize Kubernetes security and compliance with VMware Tanzu OSS. Detect vulnerabilities, manage configurations, and support enterprise-grade container images with Tanzu OSS Health Assessment." />
        <meta property="og:image" content="https://ankasoft.co/static/media/Tanzu_OSS_banner.jpg" />
        <meta property="og:url" content="https://ankasoft.co/services/tanzu-oss-service-promotion" />
        <meta property="og:type" content="website" />
      </Helmet>

      <div className="fh5co-narrow-content">
        <div className="row">
          <h3 className="fh5co-heading">Tanzu OSS Service - Kubernetes Security Report</h3>
          <div className="col-sm-6">
            <img className="img-responsive img-common" src={Image} alt="a" />
          </div>
          <div className="col-sm-6 centered-items">
            <p>
              <h3 className="text-center mb-3 text-bold">What is Tanzu OSS?</h3>
              Tanzu OSS is a VMware tool that helps organizations manage and secure open-source components by scanning Kubernetes environments, Helm repositories, and Docker images to detect security vulnerabilities, misconfigurations, and compliance issues.
              It generates reports highlighting risks like containers running as root or missing network policies, and provides compliance scores based on global security standards.
            </p>
          </div>
        </div>

        <h5
          className="centered-items"
          style={{
            fontWeight: 600,
            marginTop: 30,
            fontSize: "1.5rem",
          }}
        >
          Key Benefits
        </h5>
        <div className="container">
          <div className="row">
            {offers.map((offer, index) => (
              <OffersCard offer={offer} col={3} key={index} />
            ))}
          </div>
        </div>

        <div className="container">
          <h2>Customer Use Case: Enhancing Security in Financial Services with Tanzu OSS</h2>
          <p>
            A financial services company seeks to secure and comply with industry regulations for its microservices architecture running on Kubernetes.
            Facing challenges with open-source vulnerabilities and misconfigurations, the company uses VMware Tanzu OSS Health Assessment to scan Kubernetes clusters and Helm charts.
            This approach strengthens the company’s security posture, ensures compliance, and supports a resilient operating environment in the financial sector.
          </p>
        </div>

        <div className="container mt-4">
          <h4>Outcomes</h4>
          <ul>
            <li>Enhanced Security Posture Through Comprehensive Vulnerability Management</li>
            <li>Improved Compliance with Industry Security Standards and Regulations</li>
            <li>Automated Detection and Remediation of Kubernetes Misconfigurations</li>
            <li>Optimized Management of Open-Source Dependencies with Secure Alternatives</li>
            <li>Increased Operational Efficiency by Reducing Manual Security Workload</li>
            <li>Consistent Security Policies Across Multi-Cloud and Hybrid Environments</li>
            <li>Proactive Risk Mitigation in Kubernetes Environments to Prevent Disruptions</li>
          </ul>
        </div>

        <div className="container mt-5" style={{ display: 'flex', alignItems: "flex-start", flexDirection: "column" }}>
          <h4>Brochures</h4>
          <button className="p-2 btn btn-md btn-primary btn-outline" onClick={handleDownload}>
            Download the brochures
          </button>
        </div>
        <ContactForm isTOss={true} to={"sales@ankasoft.co"}  />
      </div>
    </div>
  );
}
