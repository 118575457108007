import React from "react";
import ServiceCardList from "../../components/service/serviceCard/ServiceCardList";
import {Helmet} from "react-helmet";
import data from "../../components/service/serviceData";
export default function Service() {
  return (
    <div id="fh5co-main">
      <Helmet>
      {/* Title */}
      <title>Services | ANKASOFT</title>

      {/* Description */}
      <meta name="description" content="Our company's service page offers expertise in DevOps, cloud-native solutions, site reliability engineering, cloud automation and management, hybrid infrastructure, and technology consulting for comprehensive managed services." />

      {/* Keywords (Not: Anahtar kelimeler artık genellikle dikkate alınmaz) */}
      <meta name="keywords" content="devops, cloud native, site reability engine/engineering, cloud automation and management, hybrid infrastructure, technology consulting, manage service, automation, VMware" />

      {/* Open Graph (og) Etiketleri */}
      <meta property="og:title" content="Services | ANKASOFT" />
      <meta property="og:description" content="Our company's service page offers expertise in DevOps, cloud-native solutions, site reliability engineering, cloud automation and management, hybrid infrastructure, and technology consulting for comprehensive managed services." />
      <meta property="og:image" content="https://ankasoft.co/static/media/logo.c446759b3d8f3ed6c4701c8b31f77767.svg" />
      <meta property="og:url" content="https://ankasoft.co/services" />
      <meta property="og:type" content="website" />

    </Helmet>
      <div className="fh5co-narrow-content">
        <h2 className="fh5co-heading animate-box">Services</h2>
        <ServiceCardList data={data}/>
      </div>
    </div>
  );
}
