import StoriesTofas from "../../media/images/stories_TOFAS.png";

const products = [
  {
    title: "Tofaş & ANKASOFT: Uçtan Uca DevOps Entegrasyonu",
    description:
      "Tofaş ve ANKASOFT'un ortak projesi olan \"Uçtan Uca DevOps Entegrasyonu\" ile otomotiv sektöründe teknolojik dönüşüm inceleniyor. İnovasyon ve başarı dolu bu hikaye sektöre ilham kaynağı olacak bir başarı öyküsüdür",
    image_url: StoriesTofas,
    link: "tofas-ankasoft-uctan-uca-devops-entegrasyonu",
    icon: "fa-solid fa-infinity",
  },
  
];

export default products;
