/* eslint-disable no-useless-escape */
import React from "react";
import ContactForm from "../../components/contactForm/contactForm";
import { Helmet } from "react-helmet";

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};
export default function Contact() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <div id="fh5co-main">
      <Helmet>
        {/* Title */}
        <title>Contact | ANKASOFT</title>

        {/* Description */}
        <meta name="description" content="Give us a call or drop by anytime, we endeavour to answer all enquiries within 24 hours on business days. We will be happy to answer your questions." />

        {/* Keywords (Not: Anahtar kelimeler artık genellikle dikkate alınmaz) */}
        <meta name="keywords" content="contact, communication, ankasoft, location" />

        {/* Open Graph (og) Etiketleri */}
        <meta property="og:title" content="Contact | ANKASOFT" />
        <meta property="og:description" content="Give us a call or drop by anytime, we endeavour to answer all enquiries within 24 hours on business days. We will be happy to answer your questions." />
        <meta property="og:image" content="https://ankasoft.co/static/media/logo.c446759b3d8f3ed6c4701c8b31f77767.svg" />
        <meta property="og:url" content="https://ankasoft.co/contact" />
        <meta property="og:type" content="website" />

      </Helmet>
      <div className="fh5co-more-contact">
        <div className="fh5co-narrow-content">
          <div className="contact-cards">
            <div className="contact-card">
              <div className="card-title-container"><span className="card-title">Email</span></div>
              <div className="card-contents">
                <div className="card-content">
                  <div className="card-icon">
                    <i className="fa-solid fa-paper-plane"></i>
                  </div>
                  <div className="card-text">
                    <p>
                      <a href="mailto:info@ankasoft.co">info@ankasoft.co</a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="contact-card">
              <div className="card-title-container"><span className="card-title">Phone</span></div>
              <div className="card-contents">
                <div className="animate-box card-content">
                  <div className="card-icon">
                    <i className="fa-solid fa-phone"></i>
                  </div>
                  <div className="card-text">
                    <p>
                    <a href="tel://">+90 216 709 2931</a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="contact-card">
              <div className="card-title-container"><span className="card-title">Locations</span></div>
              <div className="card-contents">
                <div className="card-content">
                  <div className="card-icon">
                    <i class="fa-solid fa-city"></i>
                  </div>
                  <div className="card-text">
                    <div className="sub-title">
                      Headquarter
                    </div>
                    <p>
                    <a href="#">Cevizli Mah. Hukukçular Towers A Blok Kat:18 Kartal/İSTANBUL</a>
                    </p>
                  </div>
                </div>
                <div className="animate-box card-content">
                  <div className="card-icon">
                    <i class="fa-solid fa-building"></i>
                  </div>
                  <div className="card-text">
                    <div className="sub-title">
                      Kocaeli Branch
                    </div>
                    <p>
                    <a href="#">Muallimköy Mahallesi, Deniz Cd. No:143-5, Gebze/KOCAELİ</a>
                    </p>
                  </div>
                </div>
                <div className="card-content">
                  <div className="card-icon">
                    <i class="fa-solid fa-building"></i>
                  </div>
                  <div className="card-text">
                    <div className="sub-title">
                      Yalova Branch
                    </div>
                    <p>
                    <a href="#">Süleyman Bey Mah. Arabacılar Sk. No:15 Kat:3 Merkez/YALOVA</a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            
          </div>
        </div>
      </div>
      <ContactForm
        text="Give us a call or drop by anytime, we endeavour to answer all
                enquiries within 24 hours on business days. We will be happy to
                answer your questions."
        header="Get In Touch"
        to="iyilmaz@ankasoft.co"
      />

      <div id="map">
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={value} onChange={handleChange} >
            <Tab label="Headquarter" />
            <Tab label="Kocaeli Branch" />
            <Tab label="Yalova Branch" />
          </Tabs>
        </Box>
        <CustomTabPanel value={value} index={0}>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1507.6046296202048!2d29.1796890582438!3d40.91115742184353!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14cac4635354635b%3A0x856db28fad7af9cb!2sHukuk%C3%A7ular%20Towers!5e0!3m2!1str!2str!4v1660645866833!5m2!1str!2str"
            width="100%"
            height="700"
            style={{ border: 0, padding: 15 }}
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
            title="ds"
          ></iframe>
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3020.8238654635406!2d29.50819927654975!3d40.787886232965576!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14cb23f79188ba2d%3A0x7b49ab05e94058bf!2sAnkasoft%20Bili%C5%9Fim%20Vadisi%20%C5%9Eubesi!5e0!3m2!1str!2str!4v1697640221767!5m2!1str!2str"
            width="100%"
            height="700"
            style={{ border: 0, padding: 15 }}
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
            title="ds"
          ></iframe>
        </CustomTabPanel>
        <CustomTabPanel value={value} index={2}>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3026.741294509512!2d29.268839676545024!3d40.657632840941446!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14cae5a1cb479c27%3A0xbb598b4692317ff9!2sANKASOFT%20Yalova%20Branch!5e0!3m2!1str!2str!4v1697640020086!5m2!1str!2str"
            width="100%"
            height="700"
            style={{ border: 0, padding: 15 }}
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
            title="ds"
          ></iframe>
        </CustomTabPanel>

      </div>
      <div className="policy-link-container w-100">
        <a href="./policy" className="policy-link">Privacy Policy</a>
      </div>
    </div>

  );
}
