import Carousel from "react-bootstrap/Carousel";
import "./Slider.css";
import ImgSlider from "../../media/images/webbg.png";
import CrossplaneLogo from "../../media/images/crossplane.png";
import VRALogo from "../../media/images/vra.png";
import ServiceNowLogo from "../../media/images/service-now.png";
import AqueSecLogo from "../../media/images/aquasec.png";
import React, { useEffect } from "react";
//import { NavLink } from "react-router-dom";
import $ from "jquery";
const slides = [
  {
    label: `vRealize Automation Provider for Crossplane`,
    description:
      "On a single platform, you may automate several Public Cloud services with Crossplane, an automation tool based on APIs. For users who use Private Cloud to take advantage of this service as well, we built the vRealize Automation Provider for Crossplane. You may control all the automation procedures you have created for Private Cloud with this Provider from Crossplane.",
    link: "/services",
    logos: [CrossplaneLogo, VRALogo],
  },
  {
    label: `Service Now Plugin for Crossplane`,
    description:
      "You may automate several Public Cloud services on a single platform with Crossplane, an API-based automation tool. The Service Now Plugin for Crossplane is created to expose services that are developed in Crossplane as catalog items in Service Now portal automatically.",
    link: "/services",
    logos: [CrossplaneLogo, ServiceNowLogo],
  },
  {
    label: `Aqua Platform`,
    description:
      "We offer consulting services for Aqua Platform's container, kubernetes, platform, virtual machine, serverless, and software security solutions through our qualified teams.",
    link: "/services",
    logos: [AqueSecLogo],
  },
];

export default function Slider() {
  useEffect(() => {
    fullHeight();
  }, []);

  const fullHeight = function () {
    $(".js-fullheight").css("height", $(window).height());
    $(window).on("resize", function () {
      $(".js-fullheight").css("height", $(window).height());
    });
  };
  const printLogos = (logos) => {
    return logos.map((logo, index) => {
      return (
        <img
          className="logo-img"
          src={logo}
          key={index}
          alt=""
          style={{ margin: 10, maxWidth: 200 }}
        />
      );
    });
  };
  return (
    <aside id="fh5co-hero">
      <Carousel fade={true}>
        {slides.map((slide, index) => {
          return (
            <Carousel.Item key={index}>
              <div className="container-fluid" style={{ margin: 0, padding: 0 }}>
                <img src={ImgSlider} alt="" className="js-fullheight full-img" />
              </div>
              <Carousel.Caption>
                <div className="slider-text-inner js-fullheight">
                  <h1>{slide.label}</h1>
                  <h4>{slide.description}</h4>
                  {printLogos(slide.logos)}
                </div>
              </Carousel.Caption>
            </Carousel.Item>
          );
        })}
      </Carousel>
    </aside>
  );
}
