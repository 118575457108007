import React from "react";
import BlogCard from "./BlogCard";

export default function BlogCardList({ posts, column }) {
  const renderedPosts = posts.map((post, index) => {
    return (
      <React.Fragment key={index}>
        <div className={`col-sm-${column} col-padding animate-box`}>
          <BlogCard post={post} />
        </div>
      </React.Fragment>
    );
  });
  return <div className="row row-bottom-padded-sm"> {renderedPosts}</div>;
}
