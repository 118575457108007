import React from "react";
import Image from "../../media/images/techconsulting.jpg";
import OffersCard from "../../components/offersCard/OffersCard";
import { Helmet } from "react-helmet";
import Image1 from "../../media/images/ServiceCMCSS.jpeg"
import "./serviceCommon.css";

export default function ServiceCMCSS() {
  return (
    <div id="fh5co-main" className="p-5">
      <Helmet>
        {/* Title */}
        <title>Cloud Migration Consultancy and Support Services | ANKASOFT</title>

        {/* Description */}
        <meta name="description" content="Explore our technology consulting service, where our experts provide strategic guidance and tailored solutions to address your organization's unique technological challenges and opportunities." />

        {/* Keywords (Not: Anahtar kelimeler artık genellikle dikkate alınmaz) */}
        <meta name="keywords" content="Technology consulting, experts, strategic guidance, tailored solutions, technological challenges, opportunities" />

        {/* Open Graph (og) Etiketleri */}
        <meta property="og:title" content="Cloud Migration Consultancy and Support Services | ANKASOFT" />
        <meta property="og:description" content="The gaming industry is a constantly evolving sector, and in this dynamic environment, game development firms require expert guidance to navigate the challenges they may encounter when transitioning to cloud technologies. That's where we come in." />
        <meta property="og:url" content="https://ankasoft.co/services/cloud-migration-consultancy-and-support-services" />
        <meta property="og:type" content="website" />

      </Helmet>
      <div className="row">
        <h3 className="fh5co-heading">Cloud Migration Consultancy and Support Services</h3>
        <div className="col-sm-6">
          <img className="img-responsive img-common" src={Image1} alt="a" />
        </div>
        <div className="col-sm-6 centered-items">
          <p>The gaming industry is a constantly evolving sector, and in this dynamic environment, game development firms require expert guidance to navigate the challenges they may encounter when transitioning to cloud technologies. That's where we come in.</p>
        </div>
      </div>
      <h2>Cloud Migration Consultancy and Support Services</h2>
      <p>Our Cloud Migration Consultancy and Support Services are specifically designed for game development firms, providing the consultancy and support needed during the transition to cloud technologies. We assist our clients in defining cloud migration strategies, adapting existing infrastructures to the cloud, managing data migration processes, and ensuring security in the cloud environment.</p>
    <h2>Advantages</h2>
    <ul>
        <li><strong>Expert Guidance:</strong> We provide expert guidance and consultancy to firms during the cloud migration process.</li>
        <li><strong>Customized Strategies:</strong> We offer customized cloud migration strategies tailored to the needs of our clients.</li>
        <li><strong>Smooth Transition:</strong> We manage data migration and infrastructure adaptation processes seamlessly.</li>
        <li><strong>Ensuring Security:</strong> We take the necessary measures to ensure security in the cloud environment.</li>
    </ul>
    <p>By transitioning to the cloud with us, you can optimize your game development processes, reduce costs, and gain a competitive edge.</p>
    <p>Feel free to contact us for more information.</p>
    </div>
  );
}
