import React, { useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';


function PdfViewer() {

  const [expanded, setExpanded] = React.useState('panel1');

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <div id="fh5co-main" >

      <div className="accordion-container px-4 py-2">
        <div className="title-container">ANKASOFT Politikaları</div>
        <Accordion className="custom-" onChange={handleChange('panel1')}>
          <AccordionSummary className="head" aria-controls="panel1d-content" id="panel1d-header">
            <Typography><h3>Privacy Policy</h3></Typography>
          </AccordionSummary>
          <AccordionDetails className="body">
            <div className="document-container">

              <h4>ANKASOFT Privacy Policy</h4>
              <p>&nbsp;</p>
              <p>Ankasoft Yazilim Anonim Sirketi ("Ankasoft", "we", "us" and "our"), headquartered in the Turkey is committed to
                protecting the privacy of individuals who interact with us, such as our website visitors, customers, business
                partners, leads, and prospects, recipients of marketing communications, and end users ("you" and "your"). This
                Privacy Notice explains what information we collect, why we collect it, how we use it, and your rights, when:
              </p>
              <ul>
                <li>You visit, interact with or use any of our websites, social media pages, online advertisements, marketing or
                  sales communications (our "online Properties");</li>
                <li>You visit any of our offices (&ldquo;Offices&rdquo;).</li>
              </ul>
              <p>&nbsp;</p>
              <p>This Privacy Policy (Policy) describes how we collect, hold, use and disclose your personal information, and how
                we maintain the quality and security of your personal information.</p>
              <p>&nbsp;</p>
              <h4>What information we collect and how we collect it</h4>
              <h5>What is personal information?</h5>
              <p>&ldquo;Personal information&rdquo; means any information or opinion, whether true or not, and whether recorded in
                a material form or not, about an identified individual or an individual who is reasonably identifiable. In
                general terms, this includes information or an opinion that personally identifies you either directly (e.g. your
                name) or indirectly.</p>
              <p>&nbsp;</p>
              <h5>What personal information do we collect?</h5>
              <p>We will collect personal information when you voluntarily provide it to us (including to our service providers or
                other parties who collect it on our behalf). For example, we collect personal information when you order,
                register for (or to use) or request information about Ankasoft products, services, or apps, subscribe to
                marketing communications, request support, complete surveys or provide in product feedback. We may also collect
                personal information from you offline, such as during phone calls with sales representatives, or when you
                contact customer support.</p>
              <p>&nbsp;</p>
              <p>The personal information we collect about you may include:</p>
              <ul>
                <li>name;</li>
                <li>mailing or street address;</li>
                <li>date of birth;</li>
                <li>email address;</li>
                <li>phone number;.</li>
              </ul>
              <p>&nbsp;</p>
              <p>You do not have to provide us with your personal information. Where possible, we will give you the option to
                interact with us anonymously or by using a pseudonym. However, if you choose to deal with us in this way or
                choose not to provide us with your personal information, we may not be able to provide you with our services or
                otherwise interact with you.</p>
              <p>&nbsp;</p>
              <h4>How we use your information</h4>
              <p>Personal information is used for the following purposes (unless restricted by applicable law):</p>
              <p>&nbsp;</p>
              <p>Communicate, Respond to Requests and Engage in/Process Transactions. We may use personal information to
                communicate with you, respond to your requests or provide information requested by you.</p>
              <p>&nbsp;</p>
              <p>Facilitate the Delivery of the Service and Account Administration. We may use personal information to provide you
                with Services, manage your account, and communicate with you about your use of our Services. This may include
                providing support and recommendations and sending other administrative or account-related communications. We
                will also use personal information to facilitate the delivery of Services, including tracking entitlements,
                verifying compliance, controlling access to the Service, and maintaining the security and operational integrity
                of our IT infrastructure and our Services.</p>
              <p>&nbsp;</p>
              <p>Provide Support. We use personal information and other data collected from you, in combination with other data we
                may have, in order to provide you with support in relation to our Properties.</p>
              <p>&nbsp;</p>
              <p>Marketing. We will use information obtained from you, your interactions with us, and from third-party sources to
                position and promote our products and services, deliver targeted and relevant marketing communications to you,
                to determine the effectiveness of our marketing and promotional campaigns, improve the contextual relevance of
                the content we send to you, and to generate lead scoring to determine the interest in our products and services.
                We may use information obtained from you in an aggregated format to support analytical efforts to better
                understand our website visitors&rsquo; interests and optimize content consumption and to undertake qualification
                scoring to model audiences.</p>
              <p>&nbsp;</p>
              <p>Security. We may use personal information to help monitor, prevent, and detect fraud, enhance security, monitor,
                and verify identity or access, and combat spam or other malware or security risks.</p>
              <p>&nbsp;</p>
              <p>Other Legitimate Business Purposes. We may use personal information when it is necessary for other legitimate
                purposes such as protecting our confidential and proprietary information. Except as may be permitted by
                applicable law, we will not process your information in a manner inconsistent with the purpose for which we
                collected it.</p>
              <p>&nbsp;</p>
              <h4>How We Disclose Your Information</h4>
              <p>We may disclose your personal information to third parties in accordance with this Policy in circumstances where
                you would reasonably expect us to disclose your information. For example, we may disclose your personal
                information to:</p>
              <ul>
                <li>our IT providers;</li>
                <li>our marketing providers;</li>
                <li>our accounting providers;</li>
                <li>also compliance with laws or any competent law enforcement body, regulatory body, government agency, court
                  or third party. We may disclose information where we believe disclosure is necessary or required (i) by law
                  or regulation, to comply with legal process or government requests (including in response to public
                  authorities to meet national security or law enforcement requirements); or (ii) to exercise, establish or
                  defend our legal rights.</li>
              </ul>
              <h4>&nbsp;</h4>
              <h4>Transfer of personal information overseas</h4>
              <p>Personal information, including personal information collected from or about you may be transferred, stored, and
                processed by us and our IT providers, marketing providers, accounting providers in countries other than where
                your personal information was collected and other than where you reside, including the Turkey and other
                countries whose data protection laws may be different than the laws of your country. We will protect your
                personal information in accordance with this Privacy Notice wherever it is processed and will take appropriate
                steps to protect your personal information in accordance with this Privacy Notice and applicable laws. We have
                implemented similar appropriate safeguards with our providers.</p>
              <p>&nbsp;</p>
              <h4>How do we protect your personal information</h4>
              <p>We maintain (and require our service providers to maintain) appropriate organizational and technical measures
                designed to protect the security and confidentiality of any personal information we process. These measures
                include physical access controls, encryption, Internet firewalls, intrusion detection and network monitoring
                depending on the nature of the information and the scope of processing. Ankasoft staff who may have access to
                personal information are required to keep that information confidential. Despite these controls, we cannot
                completely ensure or warrant the security of your information and encourage you to take steps to protect your
                information when disclosing personal information online by using readily available tools, such as Internet
                firewalls, anti-virus and anti-spyware software, and similar technologies.</p>
              <p>&nbsp;</p>
              <h4>Retention of personal information</h4>
              <p>We will not keep your personal information for longer than we need to. In most cases, this means that we will
                only retain your personal information for the duration of your relationship with us unless we are required to
                retain your personal information to comply with applicable laws, for example record-keeping obligations.</p>
              <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</p>
              <h4>Inquiries and complaints</h4>
              <p>For complaints about how Ankasoft handles, processes, or manages your personal information, please contact
                info@ankasoft.co. Note we may require proof of your identity and full details of your request before we can
                process your complaint.</p>
              <p>&nbsp;</p>
              <h4>How to contact us</h4>
              <p>If you have a question or concern in relation to our handling of your personal information or this Policy, you
                can contact us for assistance as follows:</p>
              <p>&nbsp;</p>
              <p>Email:</p>
              <p><a href="mailto:info@ankasoft.co">info@ankasoft.co</a></p>
              <p>&nbsp;</p>
              <p>Post:</p>
              <p>Attention: Ismail Yilmaz</p>
              <p>Address: Ankasoft Yazilim Anonim Sirketi. Cevizli Mah. Hukukcular Towers A Blok Kat:18 Kartal Istanbul Turkey</p>
              <p>&nbsp;</p>
            </div>
          </AccordionDetails>
        </Accordion>
        <Accordion className="custom-" expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
          <AccordionSummary className="head" aria-controls="panel2d-content" id="panel2d-header">
            <Typography><h3>Bilgi Güvenliği Politikası</h3></Typography>
          </AccordionSummary>
          <AccordionDetails className="body">
            <h4>Şirketimiz Bilgi Güvenliği ve Kişisel Veri Yönetim Sistemi Politikası</h4>
            <p>Şirketimiz Bilgi güvenliği ve kişisel veri yönetim sisteminin sürekli gelişimini sağlamak amacıyla; kabul edilebilir seviyenin üzerinden bulunan tüm varlıklar için gerekli kontrolleri uygulamayı, bilgi güvenliği süreçlerinin performanslarını ölçmeyi, bu verilerden hedefler üretmeyi, alt yapı, çalışma ortamı, donanım, yazılım ve yatırımlarıyla bilginin gizliliği, bütünlüğü ve erişimine ilişkin riskleri belirlemeyi ve en aza indirgemeyi, müşterilerimizin bilgilerinin güvenliğini yasal şartlara uygun olarak karşılamayı taahhüt eder.</p>

            <h5>Kişisel Veri Yönetim Sistemi Politikası</h5>
            <ol>
              <li>Başta özel hayatın gizliliği olmak üzere kişilerin temel hak ve özgürlüklerini korumak bağlamında Türkiye ve Avrupa Birliği Üye ülke kanunlarına uyum içinde kişisel verileri toplamakta ve işlemektedir.</li>
              <li>Tüm kişisel veri işleme faaliyetlerimiz, politikamız kapsamındadır. Bunlara; çalışanların, çalışan adaylarının, müşterilerin, tedarikçilerin kişisel ve özel nitelikli kişisel verilerinin işlenmesi ve herhangi bir kaynaktan toplanan ve işlenen herhangi bir veri de dâhildir.</li>
              <li>Kişisel verilerin hukuka aykırı olarak işlenmesini önlemekte ve verilerin muhafazasını sağlamak için uygun güvenlik düzeyini sağlamaya yönelik gerekli teknik ve idari tedbirleri almakta, bu kapsamda gerekli denetimleri yapmakta ve yaptırmaktadır.</li>
              <li>Kişisel verilerin işlenmesi konusunda; hukuka ve dürüstlük kurallarına uygun, doğru ve gerektiğinde güncel; belirli, açık ve meşru amaçlar güdülerek; amaçla bağlantılı, sınırlı ve ölçülü bir biçimde kişisel veri işleme faaliyetinde bulunulmaktadır.</li>
              <li>Kanunlarda öngörülen veya kişisel veri işleme amacının gerektirdiği süre kadar verileri muhafaza etmektedir.</li>
              <li>Kişisel veri sahiplerini aydınlatmakta ve kişisel veri sahiplerinin bilgi talep etmeleri durumunda gerekli bilgilendirmeyi yapmaktadır.</li>
              <li>Özel nitelikli kişisel verilerin işlenmesi bakımından öngörülen düzenlemelere uygun hareket ederek ve kanunlarda açıkça öngörülen haller dışında açık rıza olmaksızın fiil ve faaliyet gerçekleştirmemektedir.</li>
              <li>Kişisel verilerin aktarılması konusunda kanunda öngörülen düzenlemelere uygun hareket ederek fiil ve faaliyetlerini gerçekleştirmektedir.</li>
              <li>İç ve dış konularda yer alan taraflarına kişisel verilerin korunmasına yönelik bilinç oluşturmakta ve ilgili yükümlülüklerini aktarmaktadır.</li>
              <li>Kişisel Verilerin korunmasına yönelik farkındalığın arttırılması amacıyla teknik ve davranışsal yetkinliklerin geliştirilmesine yönelik eğitimler düzenlemektedir.</li>
              <li>Kişisel veri ihlallerini yönetmekte disiplin prosedürüne göre konuyu değerlendirerek ceza gerektiren suçlar ve ihlaller için kurul yetkililerine derhal bilgi vermektedir.</li>
              <li>Süreç bazlı varlık envanteri ve süreç bazlı risk değerlendirme ile kişisel verilerin gizliliğini, bütünlüğünü, verilerin güncelliğini ve kişisel veriye erişim yetkililerini sürekli takip etmektedir.</li>
              <li>Kişisel Veri Koruma Yönetim Sistemi’ne yönelik uygulamaların gerçekleştirilmesi, sistematik olarak yönetilmesi, sistemin sürekli iyileştirilmesinin sağlanması ve sistemin ihtiyaç duyduğu kaynakların ayrılmasını taahhüt etmektedir.</li>
            </ol>

            <h5>Hizmet Yönetim Sistemi Politikası</h5>
            <p>ANKASOFT YAZILIM, müşteri memnuniyetini göz önünde bulundurarak hizmetlerinin en iyi seviyede sunulmasını, sürekli gelişmesini, sürekliliğini yasa ve standartlara uygun olarak gerçekleştirmeyi amaçlamıştır.</p>

            <p>Hizmet Yönetimi politikamız ile; hizmet yönetim sistemimizin nasıl planlanacağını, kurulacağını, işletileceğini, gözlemleneceğini, sürdürülebileceğini ve iyileştirileceğini güvence altına almaktayız. Sistem kapasiteleri belirlenen kriterlere göre izlenir ve ihtiyaç durumuna göre iyileştirmeler yapılır.</p>

            <p>Sunduğumuz tüm hizmetlerin yeniliğe açık, sonuç ve müşteri odaklı olarak sürdürülebilirliği hedeflenmiştir. Süreçlerimizle, kaynakların daha verimli ve etkin kullanımını, hizmet süreçlerinde güvenlik, sürat ve erişilebilirlikte artışı, hizmetlerimizi hedeflediğimiz kalite seviyesinde sürdürmeyi, riskleri doğru ve zamanında yönetmeyi, iş süreçlerinin tabii olunan yasa, mevzuatlar ve gereksinimlere uyumlu olarak yönetilmesini, iş ilişkileri politikaları ile uyumlu ve etkin bir iletişim ilke olarak benimsenmiştir.</p>
          </AccordionDetails>
        </Accordion>
      </div>
    </div>
  );
}

export default PdfViewer;
