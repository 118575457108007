import React from "react";
import Image from "../../media/images/cloudautomation.jpg";
import {Helmet} from "react-helmet";
import OffersCard from "../../components/offersCard/OffersCard";
import "./serviceCommon.css";

export default function Service1() {
  const offers = [
    {
      title: "Establishing Infrastructure-as-Code (IaC)",
      content: ` The concept of Infrastructure-as-Code describes the process of managing and provisioning the technology stack using software and code, rather than by configuring individual hardware items.`,
    },
    {
      title: "Performing Data Back-ups",
      content: ` With cloud automation, IT operators can configure data back-ups to occur automatically during off-hours with no manual intervention.`,
    },
    {
      title: "Identifying and Addressing Zombie Cloud Infrastructure",
      content: ` When an organization loses track of some of its cloud-deployed instances, the result is zombie cloud infrastructure. These cloud instances are still activated, still generating a monthly bill, yet the organization is deriving essentially zero value from the asset. Cloud automation can be used to codify workflows that streamline the detection of zombie IT infrastructure and address it before it becomes a drain on the IT budget.`,
    },
    {
      title: "Establishing Version Control for Workflows",
      content: ` If your organization stores sensitive data in the cloud, cloud automation tools can help you set up version control for workflows where that data is put to use. This would allow your organization to demonstrate to a regulator that the same secure configuration was used each time the data was accessed or modified.`,
    },
  ];

  return (
    <div id="fh5co-main">
      <Helmet>
      {/* Title */}
      <title>CLOUD AUTOMATION & MANAGEMENT | ANKASOFT</title>

      {/* Description */}
      <meta name="description" content="Experience our comprehensive cloud automation and management service, optimizing resource utilization, scalability, and cost-efficiency through automated provisioning, orchestration, and monitoring of cloud infrastructure." />

      {/* Keywords (Not: Anahtar kelimeler artık genellikle dikkate alınmaz) */}
      <meta name="keywords" content="Cloud automation, cloud management, resource utilization, scalability, cost-efficiency, automated provisioning, orchestration, monitoring, cloud infrastructure" />

      {/* Open Graph (og) Etiketleri */}
      <meta property="og:title" content="CLOUD AUTOMATION & MANAGEMENT | ANKASOFT" />
      <meta property="og:description" content="Experience our comprehensive cloud automation and management service, optimizing resource utilization, scalability, and cost-efficiency through automated provisioning, orchestration, and monitoring of cloud infrastructure." />
      <meta property="og:image" content="https://ankasoft.co/static/media/automation_banner.51c68e0c2069ee585ba9.png" />
      <meta property="og:url" content="https://ankasoft.co/services/cloud-automation-management" />
      <meta property="og:type" content="website" />

    </Helmet>
      <div className="fh5co-narrow-content">
        <div className="row">
          <h3 className="fh5co-heading">CLOUD AUTOMATION & MANAGEMENT</h3>
          <div className="col-sm-6">
            <img className="img-responsive img-common" src={Image} alt="a" />
          </div>
          <div className="col-sm-6 centered-items">
            <p>
              Cloud automation is the use of technology that performs tasks with reduced human
              assistance in order to unite cloud management processes. ANKASOFT provide software
              tools and methodologies to automate some or all of the manual tasks associated with
              managing and operating cloud-based IT infrastructure. Cloud automation software tools
              can be configured to automatically control the installation, configuration, and
              management of cloud computing systems, enabling businesses to make the best use of
              their cloud-based resources.
            </p>
          </div>
        </div>
        <h5
          className="centered-items"
          style={{
            fontWeight: 400,
            marginTop: 30,
          }}
        >
          What we offer
        </h5>
        <div className="container">
          <div className="row">
            <OffersCard offer={offers[0]} col={3} />
            <OffersCard offer={offers[1]} col={3} />
            <OffersCard offer={offers[2]} col={3} />
            <OffersCard offer={offers[3]} col={3} />
          </div>
        </div>
      </div>
    </div>
  );
}
