/* eslint-disable react-hooks/exhaustive-deps */
import BlogCardList from "../../components/blogCard/BlogCardList";
import React, { useState, useEffect } from "react";
import NetworkService from "../../services/NetworkService";
import SearchBar from "../../components/searchBar/SearchBar";
import PropagateLoader from "react-spinners/PropagateLoader";
import ClipLoader from "react-spinners/ClipLoader";
import {Helmet} from "react-helmet";

import "./blog.css";

export default function Blog() {
  const limit = 12;
  const [errMsg, setErrMsg] = useState("");
  const [page, setPage] = useState(1);
  const [posts, setPosts] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [spinner, setSpinner] = useState(false);
  const [spinnerOnButton, setSpinnerOnButton] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        if (page === 1) {
          setSpinner(true);
          document.getElementById("btnMore").hidden = true;
        } else {
          setSpinnerOnButton(true);
        }

        const response = await NetworkService.get(
          `post/paginate?term=${searchTerm}&limit=${limit}&page=${page}`
        );

        if (page === 1) {
          setPosts(response.data);
        } else {
          setPosts(posts.concat(response.data));
        }
        if (response.data.length < limit) {
          document.getElementById("btnMore").hidden = true;
        } else {
          document.getElementById("btnMore").hidden = false;
        }
      } catch (error) {
        document.getElementById("btnMore").hidden = true;
        if (error.response.data.message === "no matched document found") {
          setPosts([]);
          setErrMsg("There is no post that is related to the search term.");
        } else {
          setErrMsg("Something went wrong.");
        }
      } finally {
        setSpinner(false);
        setSpinnerOnButton(false);
      }
    })();
  }, [searchTerm, page]);

  const searchOnChange = (e) => {
    setSearchTerm(e);
    setPage(1);
  };

  const renderPosts = () => {
    if (posts.length > 0) {
      return <BlogCardList posts={posts} column={3}></BlogCardList>;
    } else {
      return (
        <div className="centered-fn" style={{ marginTop: "4%" }}>
          <h5>{errMsg}</h5>
        </div>
      );
    }
  };

  return (
    <div id="fh5co-main">
      <Helmet>
      {/* Title */}
      <title>Academy | ANKASOFT</title>

      {/* Description */}
      <meta name="description" content="ANKASOFT ACADEMY is a knowledge-sharing initiative started by ANKASOFT personnel who like sharing their knowledge and participating in all levels of education in order to share with you what they have encountered, found to be significant, and learnt. We hope everyone will benefit from our articles, videos, and events." />

      {/* Keywords (Not: Anahtar kelimeler artık genellikle dikkate alınmaz) */}
      <meta name="keywords" content="Azure, development, blog, azure function, API, API gateway, API management, react, go, nginx, workflow, VRA, Vrealize, alert, notification" />

      {/* Open Graph (og) Etiketleri */}
      <meta property="og:title" content="Academy | ANKASOFT" />
      <meta property="og:description" content="ANKASOFT ACADEMY is a knowledge-sharing initiative started by ANKASOFT personnel who like sharing their knowledge and participating in all levels of education in order to share with you what they have encountered, found to be significant, and learnt. We hope everyone will benefit from our articles, videos, and events." />
      <meta property="og:image" content="https://ankasoft.co/static/media/logo.c446759b3d8f3ed6c4701c8b31f77767.svg" />
      <meta property="og:url" content="https://ankasoft.co/blog" />
      <meta property="og:type" content="website" />

    </Helmet>
      <div className="fh5co-narrow-content">
        <div className="fh5co-heading animate-box">
          <div className="row align-items: center">
            <div className="col-md-8" style={{ marginTop: "8px" }}>
              <h5>Read Our Blog</h5>
            </div>
            <div className="col-md-4">
              <SearchBar onChange={(e) => searchOnChange(e)} />
            </div>
          </div>
        </div>
        <div
          className="row row-bottom-padded-sm"
          style={{ margin: 0, fontSize: "20px", color: "black" }}
        >
          According to the ANKASOFT manifest, ANKASOFT is not a training firm. ANKASOFT ACADEMY is a
          knowledge-sharing initiative started by ANKASOFT personnel who like sharing their
          knowledge and participating in all levels of education in order to share with you what
          they have encountered, found to be significant, and learnt. We hope everyone will benefit
          from our articles, videos, and events. You may always get in touch with us if you have any
          inquiries.
        </div>
        {spinner ? (
          <div className="container spinner-div">
            <PropagateLoader loading={spinner} size={10} />
          </div>
        ) : (
          renderPosts()
        )}
        <div className="column show-more">
          <button id="btnMore" onClick={() => setPage(page + 1)}>
            {spinnerOnButton ? (
              <ClipLoader loading={spinnerOnButton} size={30} color="black" />
            ) : (
              <p>
                Show more <i className="fa-solid fa-arrow-down"></i>
              </p>
            )}
          </button>
        </div>
      </div>
    </div>
  );
}
