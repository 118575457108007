import React from "react";
import "./stories.css"
import { Helmet } from "react-helmet";
import ContentImage from "../../media/images/stories_TOFAS.png";
export default function TofasStoriesPage() {

  return (
    <div id="fh5co-main">
      <Helmet>
        {/* Title */}
        <title>Uçtan Uca DevOps Entegrasyonu | ANKASOFT</title>

        {/* Description */}
        <meta name="description" content="Tofaş ve ANKASOFT'un işbirliğiyle gerçekleştirilen Uçtan Uca DevOps Entegrasyonu projesi, otomotiv sektöründe teknolojik ve kültürel dönüşümü sağlayarak sektöre örnek teşkil ediyor." />

        {/* Keywords (Not: Anahtar kelimeler artık genellikle dikkate alınmaz) */}
        <meta name="keywords" content="Tofaş, ANKASOFT, DevOps, otomotiv sektörü, teknolojik dönüşüm, kültürel dönüşüm" />

        {/* Open Graph (og) Etiketleri */}
        <meta property="og:title" content="Tofaş ve ANKASOFT İşbirliği: Uçtan Uca DevOps Entegrasyonu" />
        <meta property="og:description" content="Tofaş ve ANKASOFT'un işbirliğiyle gerçekleştirilen Uçtan Uca DevOps Entegrasyonu projesi, otomotiv sektöründe teknolojik ve kültürel dönüşümü sağlayarak sektöre örnek teşkil ediyor." />
        <meta property="og:image" content={ContentImage} />
        <meta property="og:url" content="https://ankasoft.co/products/tofas-ankasoft-devops" />
        <meta property="og:type" content="website" />
        
      </Helmet>
      <div className="fh5co-narrow-content">
        <header>
          <h1 className="page-title">Uçtan Uca DevOps Entegrasyonu</h1>
        </header>
        <main>
          <div className="video-container">
            <iframe width="100%" height="400" src="https://www.youtube.com/embed/u7N4kcCxnEY?si=f5PukyQIC47ooJb-" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
          </div>
          <section>
            <h2>Giriş</h2>
            <p>
              Türkiye'nin otomotiv sektöründe öncü konumda bulunan Tofaş ve yazılım altyapısı konusunda derin bilgi birikimine sahip ANKASOFT'un işbirliği ile gerçekleştirilen "Uçtan Uca DevOps Entegrasyonu" projesini ele alacağız. Bu yazımızda, teknolojik dönüşüm sürecindeki zorlukları, atılan adımları ve elde edilen başarıları inceliyoruz. İnovasyonun ve sürekli gelişimin birleştiği bu hikâye, sadece iki şirketin işbirliğinden ibaret değil, aynı zamanda sektöre ilham kaynağı olacak bir başarı öyküsüdür.
            </p>
          </section>
          <section>
            <h2>Tofaş ve ANKASOFT İşbirliği</h2>
            <p>
              Tofaş, Stellantis bünyesinde global bir oyuncu olarak, üretim kapasitesi ve Ar-Ge yetenekleriyle dikkat çekmektedir. Sürekli kendini yenileme arayışında olan Tofaş, dört yıl önce ANKASOFT ile işbirliğine giderek iş süreçlerinde büyük bir dönüşüm başlattı. ANKASOFT'un yazılım ve altyapı konusundaki derin uzmanlığı, Tofaş'ın karşılaştığı zorlukların üstesinden gelmede kritik bir rol oynadı.
            </p>
          </section>
          <section className="highlighted">
            <h2>"Hedefler ve Çözüm Süreci"</h2>
            <p>Tofaş, DevOps liderliğini güçlendirme ve yazılım geliştirme süreçlerini modern çağın gerekliliklerine uygun hale getirme hedefiyle yola çıktı. ANKASOFT, bu süreci başlatmak için Tanzu ve TAP gibi öncü teknolojilerle bir Proof of Concept (PoC) çalışması gerçekleştirdi. PoC'nin başarılı olmasının ardından, tasarım sürecine geçildi ve 400'den fazla uygulama Tanzu platformuna entegre edildi. Bu süreç, Tofaş'ın iş süreçlerinde ve teknolojik altyapısında önemli iyileştirmeler sağladı. (vmware ve tanzu daha detaylı bilgi)</p>
          </section>
          <section>
            <h2>Eğitimler ve Kültürel Dönüşüm</h2>
            <p>
              Teknolojik dönüşümün yanı sıra, ANKASOFT, Tofaş'ın DevOps kültürünü oluşturma ve yenilikçi projeleri destekleme kapasitesini artırmak için önemli katkılarda bulundu. Bu kültürel dönüşüm, teknolojik ilerlemenin yanı sıra, ekipler arası işbirliğini ve adaptasyon yeteneğini güçlendirdi.
              </p>
          </section>
          <section>
            <h2>Sonuçlar ve Etki</h2>
            <p>
              ANKASOFT ve Tofaş işbirliği, teknolojik ve kültürel dönüşüm sağlayarak, otomotiv sektörüne örnek teşkil etti. Bu işbirliği, benzer dönüşümler planlayan diğer şirketlere yol gösterici oldu ve doğru ihtiyaçların belirlenmesi ile güçlü partner seçiminin önemini vurguladı.
              Bu başarı hikayesi, inovasyon ve sürekli gelişimin zorlukların üstesinden nasıl gelinmesi gerektiğini ortaya koyuyor. Tofaş ve ANKASOFT, teknolojik dönüşüm yolculuğunda gösterdikleri kararlılık, işbirliği ve yenilikçilikle sektörde nasıl fark yaratılabileceğinin canlı bir örneğini sunuyor.
            </p>
          </section>
        </main>
      </div>
    </div>
  );
}
