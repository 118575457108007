import { Routes, Route } from "react-router-dom";
import SideBar from "./components/sidebar/SideBar.jsx";
import Home from "./pages/home/Home";
import Blog from "./pages/blog/Blog";
import NotFound from "./pages/NotFound";
import BlogDetail from "./pages/blog/BlogDetail";
import Contact from "./pages/contact/Contact";
import About from "./pages/about/About";
import Service from "./pages/service/Service";
import Product from "./pages/products/Product";
import SiteReliEngineer from "./pages/service/SiteReliEngineer";
import Devops from "./pages/service/Devops";
import ManageService from "./pages/service/ManageService";
import CloudNative from "./pages/service/CloudNative";
import CloudAutomation from "./pages/service/CloudAutomation";
import HybridInfrastructure from "./pages/service/HybridInfrastructure";
import TechnologyConsulting from "./pages/service/TechnologyConsulting";
import CSMProduct from "./pages/products/CSMProduct";
import PdfViewer from "./pages/policy/Policy.jsx";
import Service7 from "./pages/service/ManageService";
import TKGService from "./pages/service/TKGService.jsx";
import TanzuOSSService from "./pages/service/TanzuOSS.jsx";
import CrossplaneProviders from "./pages/products/CrossplaneProviders.jsx";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import $ from "jquery";
import Quote from "./pages/quote/quote.jsx";
import DemoRequest from "./pages/demo-request/demo.jsx";
import WomenInTech from "./pages/women-in-tech/women-in-tech.jsx";
import FinopsConsultancy from "./pages/service/FinopsConsultancy.jsx";
import ServiceCMCSS from "./pages/service/CMCSS.jsx";
import Stories from "./pages/stories/Stories.jsx";
import TofasStoriesPage from "./pages/stories/TofasAnkasoftStories.jsx";
function App() {
  $(document).on("click", function (e) {
    var container = $("#fh5co-aside, .js-fh5co-nav-toggle");
    if (!container.is(e.target) && container.has(e.target).length === 0) {
      if ($("body").hasClass("offcanvas1")) {
        $("body").removeClass("offcanvas1");
        $(".js-fh5co-nav-toggle").removeClass("active");
      }
    }
  });

  $(window).on("scroll", function () {
    if ($("body").hasClass("offcanvas1")) {
      $("body").removeClass("offcanvas1");
      $(".js-fh5co-nav-toggle").removeClass("active");
    }
  });

  return (
    <div id="fh5co-page">
      <SideBar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/about" element={<About />} />
        <Route path="/women-in-tech" element={<WomenInTech />} />
        <Route path="/services" element={<Service />} />
        <Route path="/services/sre" element={<SiteReliEngineer />} />
        <Route path="/services/devops" element={<Devops />} />
        <Route path="/services/manage-service" element={<ManageService />} />
        <Route path="/services/cloud-automation-management" element={<CloudAutomation />} />
        <Route path="/services/cloud-native" element={<CloudNative />} />
        <Route path="/services/hybrid-infrastructure" element={<HybridInfrastructure />} />
        <Route path="/services/technology-consulting" element={<TechnologyConsulting />} />
        <Route path="/services/finops-consultancy" element={<FinopsConsultancy/>} />
        <Route path="/services/cloud-migration-consultancy-and-support-services" element={<ServiceCMCSS/>} />
        <Route path="/services/tkg-service-promotion" element={<TKGService/>} />
        <Route path="/services/tanzu-oss" element={<TanzuOSSService/>} />
        <Route path="/products" element={<Product />} />
        <Route path="/products/orphia-product" element={<CSMProduct />} />
        <Route path="/products/crossplane-providers-product" element={<CrossplaneProviders />} />
        <Route path="/stories" element={<Stories/>}/>
        <Route path="/stories/tofas-ankasoft-uctan-uca-devops-entegrasyonu" element={<TofasStoriesPage/>}/>
        <Route path="/blog" element={<Blog />} />
        <Route path="/blog/:id" element={<BlogDetail />} />
        <Route path="/quote" element={<Quote />} />
        <Route path="/policy" element={<PdfViewer />} />
        <Route path="/demo-request" element={<DemoRequest />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </div>
  );
}

export default App;
