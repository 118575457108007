/* eslint-disable no-useless-escape */
import React from "react";
import ContactForm from "../../components/contactForm/contactForm";

export default function DemoRequest() {
  return (
    <div id="fh5co-main">
      <div className="container" style={{ paddingTop: "5%" }}>
        <ContactForm
          text="Please fill out the form and we will get back to you as soon as possible."
          header="Ask For a Demo"
          to="sales@ankasoft.co"
          isDemo={true}
        />
      </div>
    </div>
  );
}
