/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import NetworkService from "../../services/NetworkService";
import {Helmet} from "react-helmet";
import Slider from "../../components/slider/Slider";
import ServiceDisplayList from "../../components/service/serviceDisplay/ServiceDisplayList";
import BlogCardList from "../../components/blogCard/BlogCardList";

export default function Home() {
  const [posts, setPosts] = useState([]);
  useEffect(() => {
    (async () => {
      try {
        const { data } = await NetworkService.get(`post/paginate?limit=4&page=1`);
        setPosts(data);
      } catch (error) {
        setPosts([]);
      }
    })();
  }, []);

  return (
    <div id="fh5co-main">
      <Helmet>
      {/* Title */}
      <title>ANKASOFT</title>

      {/* Description */}
      <meta name="description" content="ANKASOFT is a software and technology consulting business that collaborates with global enterprises to develop, manage, and safeguard multi-cloud applications, offering strategic expertise, execution experience, and comprehensive IT services, including system integration and virtualization, to enhance clients' competitiveness and flexibility in the industry." />

      {/* Keywords (Not: Anahtar kelimeler artık genellikle dikkate alınmaz) */}
      <meta name="keywords" content="ANKASOFT, software, technology consulting, worldwide enterprises, multi-cloud applications, IT solutions, data center solutions" />

      {/* Open Graph (og) Etiketleri */}
      <meta property="og:title" content="ANKASOFT" />
      <meta property="og:description" content="ANKASOFT is a software and technology consulting business that collaborates with global enterprises to develop, manage, and safeguard multi-cloud applications, offering strategic expertise, execution experience, and comprehensive IT services, including system integration and virtualization, to enhance clients' competitiveness and flexibility in the industry." />
      <meta property="og:image" content="https://ankasoft.co/static/media/logo.c446759b3d8f3ed6c4701c8b31f77767.svg" />
      <meta property="og:url" content="https://ankasoft.co/" />
      <meta property="og:type" content="website" />

    </Helmet>
      <Slider />
      
      <div className="fh5co-narrow-content">
        <h2 className="fh5co-heading animate-box" data-animate-effect="fadeInLeft">
          Services
        </h2>
        <ServiceDisplayList />
      </div>

      <section className="why_section">
        <div className="container">
          <div className="row">
            <h3 className="h-tag">Technology Index</h3>
            <h4 className="h-tag">Improve and Innovate with the Tech Trends</h4>
            <div className="col-3 offset-3">
              <p className="p-tag">
                Our team can assist you in transforming your business through latest tech
                capabilities to stay ahead of the curve.
              </p>
            </div>
            <div className="col-4">
              <ul style={{ listStyle: "none" }}>
                <li>
                  <p className="p-tag">
                    <i
                      className="fa fa-check"
                      style={{ paddingRight: 10, color: "yellowgreen" }}
                    ></i>
                    Cloud Automation Workflow Development
                  </p>
                </li>
                <li>
                  <p className="p-tag">
                    <i
                      className="fa fa-check"
                      style={{ paddingRight: 10, color: "yellowgreen" }}
                    ></i>
                    Cloud Native Technology Consulting
                  </p>
                </li>
                <li>
                  <p className="p-tag">
                    <i
                      className="fa fa-check"
                      style={{ paddingRight: 10, color: "yellowgreen" }}
                    ></i>
                    Private Cloud Consulting
                  </p>
                </li>
                <li>
                  <p className="p-tag">
                    <i
                      className="fa fa-check"
                      style={{ paddingRight: 10, color: "yellowgreen" }}
                    ></i>
                    DevOps Services
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      <div className="fh5co-narrow-content">
        <h2 className="fh5co-heading animate-box" data-animate-effect="fadeInLeft">
          Recent Blog
        </h2>

        <BlogCardList posts={posts} column={3}></BlogCardList>
      </div>

      <div id="get-in-touch">
        <div className="fh5co-narrow-content">
          <div className="row">
            <div className="col-md-4 animate-box" data-animate-effect="fadeInLeft">
              <h1 className="fh5co-heading-colored">Get in touch</h1>
            </div>
          </div>
          <div className="row">
            <div
              className="col-md-6 col-md-offset-3 col-md-pull-3 animate-box"
              data-animate-effect="fadeInLeft"
            >
              <h6 className="fh5co-lead">
                Give us a call or drop by anytime, we endeavour to answer all enquiries within 24
                hours on business days. We will be happy to answer your questions.
              </h6>
              <p>
                <Link to={"/contact"} className="btn btn-primary">
                  Learn More
                </Link>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
