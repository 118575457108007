/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import "./searchbar.css";
import { Helmet } from "react-helmet";



export default function SearchBar({ onChange }) {
  const [searchTerm, setSearchTerm] = useState("");
  useEffect(() => {
    const setTimeOutId = setTimeout(() => {
      onChange(searchTerm);
    }, 600);

    return () => clearTimeout(setTimeOutId);
    
  }, [searchTerm]);

  return (
    <>
    
      <div className="input-icons">
        <i className="fa-solid fa-search icon"></i>
        <input
          type="text"
          className="input"
          placeholder={"Type Something..."}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>
    </>
  );
}
