import React from "react";
import {Helmet} from "react-helmet";
import "./serviceCommon.css";
import Image from "../../media/images/sre.jpg";
export default function Service5() {
  return (
    <div id="fh5co-main">
      <Helmet>
      {/* Title */}
      <title>SITE RELIABILITY ENGINEERING | ANKASOFT</title>

      {/* Description */}
      <meta name="description" content="Explore our Site Reliability Engineering (SRE) service, ensuring seamless and dependable digital experiences by combining software engineering with IT operations for enhanced system reliability and performance." />

      {/* Keywords (Not: Anahtar kelimeler artık genellikle dikkate alınmaz) */}
      <meta name="keywords" content="Site Reliability Engineering (SRE), digital experiences, software engineering, IT operations, system reliability, performance" />

      {/* Open Graph (og) Etiketleri */}
      <meta property="og:title" content="SITE RELIABILITY ENGINEERING | ANKASOFT" />
      <meta property="og:description" content="Explore our Site Reliability Engineering (SRE) service, ensuring seamless and dependable digital experiences by combining software engineering with IT operations for enhanced system reliability and performance." />
      <meta property="og:image" content="https://ankasoft.co/static/media/sre_banner.a7ab91b8d9fb8f5c0ae9.jpg" />
      <meta property="og:url" content="https://ankasoft.co/services/sre" />
      <meta property="og:type" content="website" />

    </Helmet>
      <div className="fh5co-narrow-content">
        <div className="row">
          <h3 className="fh5co-heading">SITE RELIABILITY ENGINEERING</h3>
          <div className="col-sm-6">
            <img className="img-responsive img-common" src={Image} alt="a" />
          </div>
          <div className="col-sm-6 centered-items">
            <p>
              Site reliability engineering is a set of principles and practices that incorporates
              aspects of software engineering and applies them to infrastructure and operations
              problems. The main goals are to create scalable and highly reliable software systems.
              ANKASOFT keep important, revenue-critical systems up and running despite hurricanes,
              bandwidth outages, and configuration errors.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
